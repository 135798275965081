import '@availity/block-ui/src/BlockUi.css';

import React, { Component } from "react";
import BlockUi from '@availity/block-ui';

import { callBackendAPI, handleFormValidation,handleFormInputs} from '../../helpers/common';
import {STATUS_SUCCESS , MASTER_DRIVER_TIER_LEVEL,MASTER_LOCATION,DEPARTMENT_DROPDOWN}  from '../../config/global_constants';
import { translate  } from '../../helpers/intl_helpers';
import { toTitleCase  } from './../../helpers/common';

class AddEditJobTitleForm extends Component {

	constructor(props) {
        super(props)

        this.state = {
			isLoading: 	true,
			errors: {},
			fields: {
				color_code : {value: "#ffffff"}
			},
			submitted: false,
        };

		this.closeModal 				=	this.closeModal.bind(this);
		this.handleFormInputs 			=	this.handleFormInputs.bind(this);
		this.handleJobTitleFormSubmit 	=  	this.handleJobTitleFormSubmit.bind(this);
		this.fetchDataForEdit 			=  	this.fetchDataForEdit.bind(this);

    }

	/**
	 * For Edit Form
	 */
	componentDidMount() {
		this.fetchDataForEdit(this.props.masterId ? this.props.masterId :null);
    }

	componentDidUpdate(prevProps) {
        if (this.props.masterId !== prevProps.masterId) {
            this.fetchDataForEdit(this.props.masterId);
        }
    }

	fetchDataForEdit(id){
		window.document.body.classList.add("modal-open");
		window.document.body.classList.add("overflow-hidden");

		if(id){
			/** Update loading stats */
			this.setState({ isLoading: true }, () => {
				callBackendAPI([{ model: 'master', method: 'masterDetails', master_id: id }])
					.then((response) => {
						if (response.success && response.data[0].status === STATUS_SUCCESS) {
							let data = response.data[0].result || {};

							/** Update fields */
							this.setState({
								isLoading: false,
								fields: {
									master_id 			: { value: data._id || "" },
									name	  			: { value: data.name || "" },
									color_code			: { value: data.color_code || "" },
									department			: { value: data.department || "" },
									zip_code			: { value: data.zip_code || "" },
									visible_on_calendar	: { value: String(data.visible_on_calendar) || "" },
								}
							});

						}
					}).catch(err => console.log(err));

			});
		}else{
			this.setState({ isLoading : false});
		}
	}//endEditForm


	/**
	* For manage form submit event
	*
	* @param e 			as 	current form object
	* @param formType	as 	form type (login, signup)
	*
	* @return null
	*/
	handleJobTitleFormSubmit(e){
		e.preventDefault();

		const { name, master_id, color_code, department, visible_on_calendar,zip_code} = this.state.fields;
		const dropdownType	= this.props.dropdownType
		let masterId  = (master_id && master_id.value) ? master_id.value : "";

		/** Api Requested Data **/
		let params = [{
			model		 		: 'master',
			method		 		: 'addEdit',
			master_id 			: masterId,
			name				: (name && name.value) ? name.value :"",
			color_code			: (color_code && color_code.value) ? color_code.value :"",
			department			: (department && department.value) ? department.value :"",
			visible_on_calendar	: (visible_on_calendar && visible_on_calendar.value) ? visible_on_calendar.value :"",
			dropdown_type		: dropdownType,
			zip_code			: (zip_code && zip_code.value) ? zip_code.value :"",
		}];

		/** Validation Rules **/
		let validations = {
			name : {"label": translate('master.master_name','Name'), "rules" :["required"]},
		}

		if(dropdownType && dropdownType === MASTER_DRIVER_TIER_LEVEL){
			validations["color_code"] = {"label": translate('master.master_color_code','Color Code'), "rules": ["select"]};
		}

		if(dropdownType && dropdownType === MASTER_LOCATION){
			validations["department"] = {"label": translate('master.master_department','Department'), "rules": ["select"]};
		}

		this.setState({submitted: true});

		/** Handle validation and api response **/
		handleFormValidation(validations,this.state.fields, params, true, true, true ).then(response =>{
			this.setState({submitted: false});
			if(!response.success) return this.setState({ errors : response.errors});

			/** Call the function to fetch list */
			if (this.props.refreshDataTable) {
				this.props.refreshDataTable();
			}
			/** Close the modal */
			this.closeModal(false);
			/** Remove disable attribute from submit button */
			this.setState({ errors: {} , fields: {} , submitted: false});

		}).catch(err => console.log(err));
	}

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
		window.document.body.classList.remove("modal-open");
		window.document.body.classList.remove("overflow-hidden");
		if(this.props.toggleModal) this.props.toggleModal(false);
		this.setState({ errors: {} , fields: {} });
	}// end closeModal()

	/**
	* For update state fileds values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response =	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()


	render() {
		const {submitted, isLoading, fields, errors} = this.state;
		const isFormVisible 		= this.props.isFormVisible;
		const masterId 				= (fields && fields.master_id && fields.master_id.value) ? fields.master_id.value : ""
		const dropdownType			= this.props.dropdownType
		const displayDropdownType 	= dropdownType === "specialized_tools_ctr" ? translate("master.specialized_tools_ctr")  :toTitleCase(dropdownType.replace(/_/g, ' '));

		return <>
			{/* Add Modal */}
			<div
				className={"modal fade "+(isFormVisible ? "show d-block" :"")}
				id="adduserModal"
				tabIndex={-1}
				aria-labelledby="adduserModal"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
                            onClick={() => this.closeModal()}
						>
							X {translate("system.close")}
						</button>
						<div className="modal-body">
							<BlockUi tag="div" blocking={isLoading}>
								<h1 className="modal-title">{masterId ? translate("master.update_",null,displayDropdownType) : translate("master.add_",null,displayDropdownType)}</h1>
								<div className="adduser-form">
									<form className="adduser-form" onSubmit={(event) => this.handleJobTitleFormSubmit(event, 'adduser-form')} noValidate>
										<div className="row">
											<div className="col-12">
												<div className="form-floating">
													<input
														id="name"
														type="text"
														className="form-control"
														placeholder={translate("master.master_name")}
														value={fields["name"] ? fields["name"]["value"] : ""}
														onChange={(event) => this.handleFormInputs(event, "name")}
													/>
													<label htmlFor="name">{translate("master.master_name")}<span className="error text-danger">*</span></label>
													<span className="error text-danger">{errors["name"]}</span>
												</div>
											</div>
											{dropdownType && dropdownType === MASTER_DRIVER_TIER_LEVEL && (
												<>
													<div className="col-12">
														<div className="form-floating">
															<input
																id="color_code"
																type="color"
																className="form-control"
																placeholder={translate("master.master_color_code")}
																value={fields["color_code"] ? fields["color_code"]["value"] : ""}
																onChange={(event) => this.handleFormInputs(event, "color_code")}
															/>
															<label htmlFor="color_code">{translate("master.master_color_code")}<span className="error text-danger">*</span></label>
															<span className="error text-danger">{errors["color_code"]}</span>
														</div>
													</div>
												</>

											)}
											{dropdownType && dropdownType === MASTER_LOCATION && (
												<>
													<div className="col-12">
														<div className="form-floating">
															<input
																id="zip_code"
																type="text"
																className="form-control"
																placeholder={translate("master.master_zip_code")}
																value={fields["zip_code"] ? fields["zip_code"]["value"] : ""}
																onChange={(event) => this.handleFormInputs(event, "zip_code")}
															/>
															<label htmlFor="name">{translate("master.master_zip_code")}</label>
														</div>
													</div>
													<div className="col-12">
														<label htmlFor="department" >{translate("master.department")}<span className="error text-danger">*</span></label>
														<div className='checkbox-row employee-checkbox-row'>
															{DEPARTMENT_DROPDOWN.map((element, index) =>
																<div className="form-check form-check-inline custom-checkbox mb-3" key={"department-" + index}>
																	<input
																		className="form-check-input"
																		type="checkbox"
																		name="department"
																		id={element.value}
																		value={element.value}
																		onChange={(event) => this.handleFormInputs(event, "department")}
																		checked={fields.department && fields.department.value ?
																			(fields.department.value.constructor === Array && fields.department.value.indexOf(element.value) !== -1 ?
																				true
																				:
																				fields.department.value.constructor === String && fields.department.value === element.value ?
																					true
																					: false
																			)
																			: false}
																	/>
																	<label className="form-check-label" htmlFor={element.value}>
																		{element.label}
																	</label>
																</div>
															)}
														</div>
														<span className="error text-danger">{errors["department"]}</span>
													</div>
												</>

											)}
										</div>
										<button type={submitted ? "button" :"submit"} className="btn btn-fill form-control width-initial mt-3">
											{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
										</button>
									</form>
								</div>
							</BlockUi>
						</div>
					</div>
				</div>
			</div>
			{isFormVisible && <div className="modal-backdrop fade show"></div>}
		</>
	}
}
export default AddEditJobTitleForm;
