import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from 'react'
import {AsyncPaginate} from 'react-select-async-paginate';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';

import { translate, formatDate } from '../../../helpers/intl_helpers';
import { CALENDAR_DATE_TIME_FORMAT, DATE_RANGE_PICKER_FORMAT, RADIO_YES,RADIO_NO } from '../../../config/global_constants';
import { handleFormValidation, handleFormInputs,  callBackendAPI, reFormatErrors, customConfirmBox, isWeekendDay  } from '../../../helpers/common';

export default class AddPtoYardJob extends Component {
	constructor(props) {
        super(props);
        let dateObj 	=	(this.props.dateObj) ? this.props.dateObj :{};
		let dragItem	= 	(dateObj && dateObj.drag_item && (dateObj.drag_item !== undefined)) ? dateObj.drag_item :"";
		let employees	=	[];
		let unpaidEmpList=	[];
		let equipment	=	[];
		let startDate 	=	dateObj.start_date 	?	dateObj.start_date 	:new Date();
		let endDate 	=	dateObj.end_date 	? 	dateObj.end_date 	:new Date();
		let slotType	=	(dateObj && dateObj.slot_type) ? dateObj.slot_type : "";
		let empType		=	(dateObj && dateObj.emp_type) ? dateObj.emp_type : "";
		let isMobile	=	(dateObj && dateObj.isMobileScreen) ? dateObj.isMobileScreen : false;

		if(slotType === "yard" && dragItem && dragItem.state && dragItem.state && !dragItem.state.type) employees = [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}];
		if(dragItem && dragItem.state && dragItem.state && (dragItem.state.type === "asset")) equipment = [{_id:dragItem.id,value:dragItem.id,label: dragItem.name}]

		let jobDetails 	=	(this.props.jobDetails) ? this.props.jobDetails :{};
		let isEdit		=	jobDetails.isEdit ? jobDetails.isEdit : false;
		let isCopy		=	jobDetails.isCopy ? jobDetails.isCopy : false;
		let notes		=	jobDetails.notes  ? jobDetails.notes  : null;
		let subject		=	jobDetails.subject  ? jobDetails.subject  : null;
		let jobId		=	isEdit && jobDetails._id ? jobDetails._id :"";

		if(isEdit && jobDetails.unpaid_employee_ids && jobDetails.unpaid_employee_ids.length) empType = "unpaid";
		if(isEdit && jobDetails.paid_employee_list && jobDetails.paid_employee_list.length) empType = "paid";

		if(slotType === "pto" && dragItem && dragItem.id && dragItem.name){
			if(empType === "paid") employees = [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}];
			if(empType === "unpaid") unpaidEmpList = [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}];
		}

		if(isEdit && jobDetails.date && formatDate(jobDetails.date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
			startDate = jobDetails.date;
			endDate = jobDetails.date;
		}

		if(isCopy && jobDetails.selected_date && formatDate(jobDetails.selected_date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
			startDate	=	{value: jobDetails.selected_date};
			endDate		=	{value: jobDetails.selected_date};
		}

		if(jobDetails.assets && jobDetails.assets.length){
			jobDetails.assets.forEach(element => {
				equipment.push({_id: element.id, value: element.id, label: element.label});
			});
		}
		
		if(slotType === "yard"){
			if(jobDetails.crewMembers && jobDetails.crewMembers.length){
				jobDetails.crewMembers.forEach(element => {
					employees.push({_id: element.id, value: element.id, label: element.full_name});
				});
			}
		}else{
			if(jobDetails.paid_employee_list && jobDetails.paid_employee_list.length && empType === "paid"){
				jobDetails.paid_employee_list.forEach(element => {
					employees.push({_id: element.id, value: element.id, label: element.full_name});
				});
			}

			if(jobDetails.unpaid_employee_list && jobDetails.unpaid_employee_list.length && empType === "unpaid"){
				jobDetails.unpaid_employee_list.forEach(element => {
					unpaidEmpList.push({_id: element.id, value: element.id, label: element.full_name});
				});
			}
		}
		
        this.state = {
			fields: {
                start_date		: 	{value: startDate},
                end_date		: 	{value: endDate},
                slotType		: 	slotType,
				employees		: 	employees.length  		? 	employees 		:null,
				unpaid_employees: 	unpaidEmpList.length	?	unpaidEmpList 	:null,
				equipment		: 	equipment.length  		?	equipment  		:null,
				notes			: 	{value: notes},
				subject			: 	{value: subject},
				date_range 		: 	[new Date(startDate), new Date(endDate)],
				is_hide_weekend_date          :   {value: jobDetails.is_hide_weekend_date ? String(jobDetails.is_hide_weekend_date) :  String(RADIO_NO)},
            },
			defaultDateRange: 	[subDays(new Date(), 6), new Date()],
			errors      :	{},
            loading     : 	true,
            submitted	:	false,
			jobId  		: 	jobId,
			isEdit  	: 	isEdit,
			isCopy  	: 	isCopy,
			paidUnpaidVal:  empType,
			isMobileScreen: isMobile
        };

        this.handleFormInputs 	= 	this.handleFormInputs.bind(this);
		this.handleFormSubmit 	=  	this.handleFormSubmit.bind(this);
		this.handleChange 		= 	this.handleChange.bind(this);
		this.getDropDownList 	=	this.getDropDownList.bind(this);
		this.closeModal			=  	this.closeModal.bind(this);
    }

	/**
	 * Handle dropdown value state
	 */
	handleChange = (fieldName) => (fieldVal) => {
        let updatedFields = {...this.state.fields, [fieldName]: fieldVal};
		if (fieldName === "date_range") {
            let startDate = fieldVal[0];
            if (startDate) updatedFields['is_hide_weekend_date'] = { value: isWeekendDay(startDate) ? String(RADIO_YES) : String(RADIO_NO) };
        }
        this.setState({fields: updatedFields});
    }; // end handleChange()

	/**
	* For get drop down list
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			const { start_date, end_date, slotType } = this.state.fields;
			let apiParams  = null;
			if(type === "employee")  apiParams = { model: 'calendar', method: 'employeeList',is_crew_member: true, is_pto : (slotType !== "yard" ? true :false), emp_name: inputValue ? inputValue : '', current_page: page,start_date:(start_date && start_date.value) ? start_date.value: "", end_date: (end_date && end_date.value) ? end_date.value : ""  };
			if(type === "equipment") apiParams = { model: 'calendar', method: 'assetsList', asset_name: inputValue ? inputValue : '', current_page: page,start_date:(start_date && start_date.value) ? start_date.value: "", end_date: (end_date && end_date.value) ? end_date.value : ""  };

			if(!apiParams) return {options: [], hasMore: false, additional: {page: page}};

            let response = await callBackendAPI([apiParams]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: false, additional: {page: page}};
		}
    }// end getDropDownList()

   /**
	* Handle time on change
	*/
   handleTimeChange = (date) => {
		this.setState({
			fields : {...this.state.fields, shift_time: {value: date}}
		});
	} // end handleTimeChange()

	 /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleFormSubmit(e,isForceAssign){
		e.preventDefault();

		/** Api Requested Data **/
		const {employees, equipment, start_date, end_date, notes, slotType, unpaid_employees, subject, date_range, is_hide_weekend_date } = this.state.fields;
		let empArr      = [];
		let equipArr    = [];
		let unEmpArr	= [];
		if(employees && employees.length) empArr = employees.map(empArr => empArr._id);
		if(equipment && equipment.length) equipArr = equipment.map(equipArr => equipArr._id);
		if(unpaid_employees && unpaid_employees.length) unEmpArr = unpaid_employees.map(empArr => empArr._id);

		this.setState({
			submitted: true,
			fields : {
				...this.state.fields,
				...{
					employee_id: {value: empArr.length ? empArr : (unEmpArr.length ? unEmpArr : null)},
					equipment_id: {value: equipArr.length ? equipArr : null},
					unpaid_employee_id: {value: unEmpArr.length ? unEmpArr : null}
				}
			}
		},()=>{

			let params = [{
				model				: 	'jobs',
				method				: 	'addYardOrPtoJob',
				job_id		    	: 	this.state.jobId ?	this.state.jobId :"",
				employee_id			: 	(empArr && empArr.length)        	?	empArr	        	:null,
				equipment_id		: 	(equipArr && equipArr.length)       ?	equipArr	        :null,
				unpaid_employee_id	: 	(unEmpArr && unEmpArr.length)       ?	unEmpArr	        :null,
				start_date			: 	(date_range && date_range[0])		?	date_range[0]		:"",
				end_date			:	(date_range && date_range[1])		?	date_range[1]		:"",
				notes				:	(notes && notes.value)				?	notes.value			:"",
				subject				:	(subject && subject.value)			?	subject.value		:"",
				type				:	slotType,
				is_hide_weekend_date:   (is_hide_weekend_date && is_hide_weekend_date.value) ? 	is_hide_weekend_date.value 			:"",
				emp_type			:   this.state.paidUnpaidVal,
			}];

			if(isForceAssign) params[0]['force_assign'] = true;

			/** Validation Rules **/
			let validations = {
				employee_id : 	{"label": translate('calendar.employees'), "rules" :["select"] },
				// start_date	: 	{"label": translate('calendar.start_date'), "rules" :["required"]},
				// end_date	:	{"label": translate('calendar.end_date'), "rules" :["required"]},
				// date_range	:	{"label": translate('calendar.select_date'), "rules" :["select"]},
			}
			
			if(slotType){
				if(slotType === "yard"){
					validations["subject"]  	=  {"label": translate('calendar.subject'), "rules" :["required"] };
					
					if (!empArr.length && !equipArr.length) {
						validations["employee_id"] = { "label": translate('calendar.employees'), "rules": ["select"] };
						validations["equipment_id"] = { "label": translate('calendar.equipment'), "rules": ["select"] };
					} else {
						// Remove the validations if either array has data
						delete validations["employee_id"];
						delete validations["equipment_id"];
					}
				}else{
					if(!empArr.length || !unEmpArr.length){
						validations["employee_id"]  =  {"label": translate('calendar.employees'), "rules" :["select"] };
					}
				}
			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params, true,true,true).then(response=>{
				if(!response.success){
					if(response.data.message){
						let tmpErrors =  response.data.message[0];
						let isEmpOrEquip = tmpErrors.force_assign;
					
						this.setState({ errors : response.errors, submitted: false},()=>{
							let message = response.data.message[0].msg;
							if(isEmpOrEquip){
								window.closeModal('addPtoYardJob');
							
								let confirmation = customConfirmBox(message);
								confirmation.then((confirmed) => {
									if (confirmed) {
										this.handleFormSubmit(e,true)
									}else{
										window.openModal('addPtoYardJob');
									}
								});
							}
						});
					}

					// return this.setState({ errors : response.errors, submitted: false});
				}else{
					/** Call parent function */
					if(this.props.updateEventCalendar) this.props.updateEventCalendar(null,"calendar");
					/** Hide loader */
					this.closeModal();
				}
			}).catch(err => console.log(err));
		})
	}

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('addPtoYardJob');
        this.setState({ submitted: false, errors: {}, fields: {} });
		this.props.handleCloseTaskModal();
	}// end closeModal()

	render() {
		const {submitted, fields, errors, isEdit, isCopy, paidUnpaidVal, isMobileScreen} = this.state;

		let isYard = fields.slotType === "yard" ? true :false;
		return <div className="modal fade crew-modal" id="addPtoYardJob" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="assSubContractorJobLabel" aria-hidden="true">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<button type="button" className="btn-close" onClick={()=>{this.closeModal()}}>X Close</button>
					<div className="modal-body">
					  	<h3>
							{isYard ?
								<>
									{isCopy ? translate('calendar.clone_yard') :(isEdit ? translate('calendar.update_yard') :translate('calendar.add_yard'))}
								</>
							:
								<>
									{isCopy ? translate('calendar.clone_pto') :(isEdit ? translate('calendar.update_pto') :translate('calendar.add_pto'))}
								</>
							}
						</h3>
						<form onSubmit={(event) => this.handleFormSubmit(event)} noValidate>
							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
								{!isEdit ?
									<div className="row">
										<div className="col-lg-6">
											<div className="form-group">
												<label htmlFor="date" className="form-label">{translate('calendar.select_date')}<span className="error text-danger">*</span></label>
												<DateRangePicker
													value={fields.date_range ? fields.date_range :null}
													format={DATE_RANGE_PICKER_FORMAT}
													size="lg"
													className='form-control input-with-label date-range-picker-common'
													onChange={this.handleChange("date_range")}
													placeholder={translate('reports.search_by_date')}
													cleanable={false}
													ranges={[]}
													editable={false}
													singledatepicker= "true"
													showdropdowns= "true"
													autoupdateinput= "true"
													parente1= "#modaldialogid"
													showOneCalendar={isMobileScreen ? true : false}
													
												/>
												<span className="error text-danger">{errors["date_range"]}</span>
											</div>
										</div>
										<div className="col-lg-4">
											<div className='form-group'>
												<label htmlFor="is_hide_weekend_date" className="form-label">{translate('calendar.is_hide_weekend_date')} </label>
												<div className='form-group checkbox-row order-checkbox-row'>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_YES) ? true :false}/>
														<label className="form-check-label" htmlFor="is_hide_weekend_dateyes">
														{translate('calendar.yes')}
														</label>
													</div>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_NO) ? true :false} />
														<label className="form-check-label" htmlFor="is_hide_weekend_dateno">
														{translate('calendar.no')}
														</label>
													</div>
												</div>
											</div>
										</div>
										{/* <div className="col-lg-6">
											<div className="form-group">
												<label htmlFor="end_date" className="form-label">{translate('calendar.end_date')} <span className="error text-danger">*</span></label>
												<DatePicker
													selected={fields.end_date && fields.end_date.value ? fields.end_date.value : null}
													onChange={(date) => {this.setState({ fields : {...fields, ...{end_date:{value: date} }} }) }}
													className="form-control"
													dateFormat={DATE_PICKER_DATE_FORMAT}
													minDate={fields.start_date && fields.start_date.value ? fields.start_date.value : ''}
													disabled={!fields.start_date ? true : false}
													placeholderText={translate('calendar.select_end_date')}
													dropdownMode="select"
													showMonthDropdown
													showYearDropdown
													customInput={<CustomInput />}
												/>
												<span className="error text-danger">{errors["end_date"]}</span>
											</div>
										</div> */}
									</div>
								:null}

								{isYard && 
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="subject" className="form-label">{translate('calendar.subject')} <span className="error text-danger">*</span></label>
												<input type="text" className="form-control" placeholder="Subject" name='subject' id='subject' value={fields["subject"] && fields["subject"]["value"] ? fields["subject"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "subject")} />
												<span className="error text-danger">{errors["subject"]}</span>
											</div>
										</div>
									</div>
								}

								{(paidUnpaidVal === "" || paidUnpaidVal === "paid") ? 
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												{isYard && (paidUnpaidVal === "") ?
													<label htmlFor="employees" className="form-label">
														{translate('calendar.employees')}
													</label>
												:
													<label htmlFor="employees" className="form-label">
														{translate('calendar.paid_employees')}
													</label>
												}
												<AsyncPaginate
													className="custom-react-select"
													value={fields.employees  ? fields.employees : null}
													loadOptions={this.getDropDownList}
													onChange={this.handleChange("employees")}
													isMulti
													additional={{ page: 1, type: "employee"}}
													placeholder={<label htmlFor="employees">{translate('calendar.select_employees')}</label>}
												/>
												<span className="error text-danger">{this.state.errors["employees"]}</span>
												<span className="error text-danger">{this.state.errors["employee_id"]}</span>
											</div>
										</div>
									</div>
								:null}

								{!isYard && (paidUnpaidVal === "unpaid" || paidUnpaidVal === "") ?
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="unpaid_employees" className="form-label">{translate('calendar.unpaid_employees')}</label>
												<AsyncPaginate
													className="custom-react-select"
													value={fields.unpaid_employees  ? fields.unpaid_employees : null}
													loadOptions={this.getDropDownList}
													onChange={this.handleChange("unpaid_employees")}
													isMulti
													additional={{ page: 1, type: "employee"}}
													placeholder={<label htmlFor="unpaid_employees">{translate('calendar.select_employees')}</label>}
												/>
												<span className="error text-danger">{this.state.errors["unpaid_employees"]}</span>
												<span className="error text-danger">{this.state.errors["unpaid_employee_id"]}</span>
											</div>
										</div>
									</div>
								:null}

								{isYard && fields ?
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="equipment" className="form-label">{translate('calendar.equipment')} </label>
												<AsyncPaginate
													className="custom-react-select"
													value={fields.equipment  ? fields.equipment : null}
													loadOptions={this.getDropDownList}
													onChange={this.handleChange("equipment")}
													isMulti
													additional={{ page: 1, type: "equipment"}}
													placeholder={<label htmlFor="equipment">{translate('calendar.select_equipments')}</label>}
												/>
												<span className="error text-danger">{this.state.errors["equipment"]}</span>
												<span className="error text-danger">{this.state.errors["equipment_id"]}</span>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="notes" className="form-label">{translate('calendar.notes')} </label>
												<textarea type="notes" className="form-control" name='notes' id='notes' value={fields["notes"] && fields["notes"].value ? fields["notes"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "notes")}></textarea>
												<span className="error text-danger">{this.state.errors["notes"]}</span>
											</div>
										</div>
									</div>
								:null}
							</div>
							<div className='col-xl-12 d-flex'>
								<button type={submitted ? "button" :"submit"} className="btn btn-fill">
									{translate('system.submit')}{submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
								</button>
								<button type="button" className="btn btn-outline ms-1" onClick={() => this.closeModal()} data-dismiss="modal">
									{translate('system.close')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	}
}

