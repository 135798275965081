import '@availity/block-ui/src/BlockUi.css';

import React, { Component } from "react";
import {AsyncPaginate} from 'react-select-async-paginate';
import BlockUi from '@availity/block-ui';

import { callBackendAPI, handleFormValidation,handleFormInputs} from '../../helpers/common';
import {STATUS_SUCCESS}  from '../../config/global_constants';
import { translate  } from '../../helpers/intl_helpers';

export default class AddEditForm extends Component {

	constructor(props) {
        super(props)

        this.state = {
			isLoading		:	true,
			errors			:	{},
			fields			:	{},
			submitted		: 	false,
			jobNumberList	:	[]
        };

		this.closeModal 				=	this.closeModal.bind(this);
		this.handleFormInputs 			=	this.handleFormInputs.bind(this);
		this.handleWorkOrderFormSubmit 	=  	this.handleWorkOrderFormSubmit.bind(this);
		this.fetchDataForEdit 			=  	this.fetchDataForEdit.bind(this);
		this.loadOptions 				=	this.loadOptions.bind(this);
		this.handleChange 				=	this.handleChange.bind(this);
	}

	/** Call a function for get work order details for Edit Form */
	componentDidMount() {
		this.fetchDataForEdit(this.props.workOrderId);
    }

	/** Call a function for get work order details for Edit Form */
	componentDidUpdate(prevProps) {
        if (this.props.workOrderId !== prevProps.workOrderId) {
            this.fetchDataForEdit(this.props.workOrderId);
        }
    }

	/** For get work order details for Edit Form */
	fetchDataForEdit(id){
		window.document.body.classList.add("modal-open");
		window.document.body.classList.add("overflow-hidden");

		if(id){
			/** Get  work order details */
			callBackendAPI([{ model: 'work_order', method: 'getWorkOrderDetails', work_order_id : id }]).then((response) => {
					if(response.success && response.data[0].status === STATUS_SUCCESS){
						let data = response.data[0].result || {};

						/** Update fields */
						this.setState({
							isLoading :false,
							fields: {
								work_order_id		: {value: data._id || "" },
								job_id				: {value: data.job_id || "", label: data.job_number || "" },
								work_order_number	: {value: data.work_order_number || "" },
							}
						});
					}
			}).catch(err => console.log(err));
		}else{
			this.setState({ isLoading: false});
		}
	}//editForm

	/**
	* For load job select options
	*
	* @param inputValue		as 	search term
	* @param loadedOptions	as 	old options array
	* @param page			as 	page count
	*
	* @return json
	*/
	async loadOptions(inputValue, loadedOptions, { page }) {
		try{
			let response = await callBackendAPI([{ model: 'calendar', method: 'cannonJobList', job_number: inputValue ? inputValue : '', current_page: page }]);

			let resData 		= response && response.data && response.data[0] ? response.data[0] :{};
			let result 			= resData.result ? resData.result :[];
			let recordsTotal 	= resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	= result.concat(loadedOptions);

			return {
				options		: result,
				hasMore		: completeArray.length  === recordsTotal ? false :true,
				additional	: {page: page + 1},
			}

		} catch (error) {
			console.error('Error loading options:', error);
			return {
				options		: 	[],
				hasMore		:	false,
				additional	: 	{page: page},
			};
		}
	}

	/**
	* For Set stats for select box selected value
	*
	* @param field	as 	selected name
	* @param value	as 	selected value
	*
	* @return null
	*/
	handleChange = (field) =>(value)=>{
		this.setState({ fields : {...this.state.fields, ...{[field]: value }} });
	}

	/**
	* For manage form submit event
	*
	* @param e 			as 	current form object
	* @param formType	as 	form type (login, signup)
	*
	* @return null
	*/
	handleWorkOrderFormSubmit(e){
		e.preventDefault();

		/** Update submit loader stats */
		this.setState({submitted: true},()=>{

			const { work_order_number , job_id, work_order_id } = this.state.fields;

			/** Api Requested Data **/
			let params = [{
				model				: 	'work_order',
				method				: 	'addEditWorkOrder',
				job_id				: 	(job_id && job_id.value)	? 	job_id.value 	:"",
				job_number			: 	(job_id && job_id.label)	?	job_id.label	:"",
				work_order_id		: 	(work_order_id && work_order_id.value)			?	work_order_id.value 	:"",
				work_order_number	: 	(work_order_number && work_order_number.value)	? 	work_order_number.value :""
			}];

			/** Validation Rules **/
			let validations = {
				job_id				: {"label": translate('job_title.job_number','Job Number'), "rules" :["required"]},
				work_order_number	: {"label": translate('job_title.work_order_number','Work Order Number'), "rules" :["required"]},
			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params, true, true, true).then(response =>{
				this.setState({submitted: false});

				if(!response.success) return this.setState({ errors : response.errors});

				/** Call the function to fetch list */
				if(this.props.refreshDataTable) {
					this.props.refreshDataTable();
				}

				/** Close the modal */
				this.closeModal(false);

				/** Remove disable attribute from submit button */
				this.setState({ errors: {} , fields: {} , submitted: false});
			}).catch(err => console.log(err));
		});
	}

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
		window.document.body.classList.remove("modal-open");
		window.document.body.classList.remove("overflow-hidden");
		if(this.props.toggleModal) this.props.toggleModal(false);
	}// end closeModal()

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	render() {
		const {submitted , fields , errors, isLoading} = this.state;
        const isFormVisible = this.props.isFormVisible;
		const workOrderId 	= (fields && fields.work_order_id && fields.work_order_id.value) ?  fields.work_order_id.value :"";

		return <>
			<div className={"modal fade mt-5 "+(isFormVisible ? "show d-block" :"")} id="adduserModal" tabIndex={-1} aria-labelledby="adduserModal" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.closeModal()} >
							X {translate("system.close")}
						</button>
						<div className="modal-body">
							<BlockUi tag="div" blocking={isLoading}>
								<h1 className="modal-title">{workOrderId ? translate("work_order.edit_work_order") : translate("work_order.add_work_order")}</h1>
								<div className="adduser-form">
									<form className="workOrder-form" onSubmit={(event) => this.handleWorkOrderFormSubmit(event, 'workOrder')} noValidate>
										<div className="row">
											<div className="col-12">
												<div className="form-floating">
													<div className="custom-form-group form-group">
														<AsyncPaginate
															className	=	"custom-react-select"
															value		=	{fields.job_id ? fields.job_id : null}
															loadOptions	=	{this.loadOptions}
															onChange	=	{this.handleChange("job_id")}
															placeholder	=	{<label htmlFor="job_number">{translate("work_order.job_number")}<span className="error text-danger">*</span></label>}
															additional	=	{{page: 1}}
														/>
													</div>
													<span className="error">{errors["job_id"]}</span>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div className="form-floating">
													<input
														id="work_order_number"
														type="text"
														className="form-control"
														placeholder={translate("work_order.work_order_number")}
														value={ this.state.fields["work_order_number"] ? this.state.fields["work_order_number"]["value"] : ""}
														onChange={(event) => this.handleFormInputs(event, "work_order_number")}
													/>
													<span className="error text-danger">{errors["work_order_number"]}</span>
													<label htmlFor="work_order_number">{translate("work_order.work_order_number")}<span className="error text-danger">*</span></label>
												</div>
											</div>
										</div>
										<button type={submitted ? "button" :"submit"} className="btn btn-fill form-control width-initial mt-3">
											{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
										</button>
									</form>
								</div>
							</BlockUi>
						</div>
					</div>
				</div>
			</div>
			{isFormVisible && <div className="modal-backdrop fade show"></div> }
		</>
	}
}

