import '@availity/block-ui/src/BlockUi.css';

import React, { Component } from "react";
import BlockUi from '@availity/block-ui';

import { translate  } from './../helpers/intl_helpers';
import {STATUS_SUCCESS ,DEPARTMENT_DROPDOWN}  from './../config/global_constants';
import { callBackendAPI, handleFormValidation,handleFormInputs } from './../helpers/common';

class AddDepartmentCost extends Component {

	constructor(props) {
        super(props)

        this.state = {
			isLoading: true,
			errors: {},
			fields: {},
			submitted: false,
        };

		this.handleFormInputs 	= this.handleFormInputs.bind(this);
		this.handleFormSubmit 	= this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
        this.fetchDepartmentCostDetails();
    }

    fetchDepartmentCostDetails = () => {
        callBackendAPI([{ model: 'department_cost', method: 'departmentCostDetails' }]).then(response => {
            if(response.success && response.data[0] && response.data[0].status === STATUS_SUCCESS) {
                let tmpStats = { isLoading: false };

                tmpStats.fields = {};

                let departmentCost = response.data[0].result || [];
                departmentCost.forEach((data) => {
                    let employee 	= data.department + "_employee_hourly_rate";
                    let equipment 	= data.department + "_equipment_hourly_rate";

                    tmpStats.fields[employee]	= 	{value: data["employee_hourly_rate"] || "" };
                    tmpStats.fields[equipment] 	=	{value: data["equipment_hourly_rate"] || "" };
                });

                /** Update stats */
                this.setState(tmpStats);
            }
        }).catch(err => console.log(err));
    }

	/**
	* For manage form submit event
	*
	* @param e 			as 	current form object
	* @param formType	as 	form type (login, signup)
	*
	* @return null
	*/
	handleFormSubmit(e){
		e.preventDefault();

		const {fields} = this.state;

		this.setState({ submitted: true },()=>{

			/** Validation Rules **/
			let validations = {}

			/** Api Requested Data **/
			let params = [{
				model	: 	'department_cost',
				method	: 	'addEditDepartmentCost'
			}];

			DEPARTMENT_DROPDOWN.forEach((department)=>{
				let employee 	= department.value+"_employee_hourly_rate";
				let equipment 	= department.value+"_equipment_hourly_rate";

				validations[employee] 	= {"label": translate(department.value,"employee hourly rate"), "rules" :["required","float"]};
				validations[equipment] 	= {"label": translate(department.value,"equipment hourly rate"), "rules" :["required","float"]};

				params[0][employee] 	= fields[employee] && fields[employee].value ? fields[employee].value : "";
				params[0][equipment] 	= fields[equipment] && fields[equipment].value ? fields[equipment].value : "";
			});

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params).then(async (response) =>{
				this.setState({submitted: false});

				if(!response.success) return this.setState({ errors : response.errors});

				this.setState({ submitted: false, errors: {}});
				this.fetchDepartmentCostDetails();
			}).catch(err => console.log(err));
		});
	}

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	render() {
		const {submitted,fields,errors,isLoading} = this.state;

		return <>
			<BlockUi tag="div" blocking={isLoading}>
				<div className="dashboard-content">
					<div className="dashboard-heading d-flex justify-content-between align-items-center">
						<div className="left-heading">
							<h1>{translate("department.department_cost")}</h1>
						</div>
					</div>
					<div className="add-employee department-cost">
						<div className="table-responsive">
							<form className="adduser-form" onSubmit={(event) => this.handleFormSubmit(event, 'addUser')} noValidate>
								<table className="table custom-table">
									<thead>
										<tr className="mb-5">
											<th><p>{translate("department.department")}</p></th>
											<th><p>{translate("department.employee_hourly_rate")} ($)</p></th>
											<th><p>{translate("department.equipment_hourly_rate")} ($)</p></th>
										</tr>
									</thead>
									<tbody>
										{DEPARTMENT_DROPDOWN.map((department) =>
											<tr key={department.value}>
												<td>
													<div className="mt-4">
														<label htmlFor="contact_name">{department.label}</label>
													</div>
												</td>
												<td>
													<div className="form-floating">
														<div>
															<input
																id={"employee_"+department.value}
																type="text"
																className="form-control"
																placeholder={translate("department.employee_hourly_rate")}
																value={fields[department.value + "_employee_hourly_rate"] ? fields[department.value + "_employee_hourly_rate"]["value"] : ""}
																onChange={(event) => this.handleFormInputs(event, department.value + "_employee_hourly_rate")}
															/>
															<span className="error text-danger">{errors[department.value + "_employee_hourly_rate"]}</span>
														</div>
													</div>
												</td>
												<td>
													<div className="form-floating">
														<div>
															<input
																id={"equipment_"+department.value}
																type="text"
																className="form-control"
																placeholder={translate("department.equipment_hourly_rate")}
																value={fields[department.value+"_equipment_hourly_rate"] ? fields[department.value+"_equipment_hourly_rate"]["value"] : ""}
																onChange={(event) => this.handleFormInputs(event, department.value + "_equipment_hourly_rate")}
															/>
															<span className="error text-danger">{errors[department.value + "_equipment_hourly_rate"]}</span>
														</div>
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
								<div className="employee-submit d-flex flex-wrap align-items-center">
									<button type={submitted ? "button" : "submit"} className="btn btn-fill">
										{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</BlockUi>
		</>
	}
}
export default AddDepartmentCost;
