import 'react-image-lightbox/style.css';

import React, { Component } from "react";
import Lightbox from 'react-image-lightbox';
import Cookies from 'universal-cookie';
import NormalDataTable from './../NormalDataTable';
import Auth from '../../helpers/Auth';

import { callBackendAPI, customConfirmBox, reFormatSuccess, upperCaseOfName, getRandomString} from './../../helpers/common';
import { PAGING_LENGTH_MENU, DEPARTMENT_DROPDOWN, PROJECT_MANAGER,ACTIVE_DEACTIVE_DROPDOWN, ACTIVE}  from './../../config/global_constants';
import { translate } from './../../helpers/intl_helpers';
import BulkEmployeeModal from './BulkEmployee';

class EmployeeList extends Component {
	constructor(props) {
        super(props)
		this.tableSectionRef = React.createRef();

        this.state = {
			rowsPerPage		: 	PAGING_LENGTH_MENU[0],
			searchStats 	: 	{active :{value : ACTIVE, label:"Active"}},
			imgUrl			:	"",
			noImgUrl		:	"",
			currentPage		:	1,
			showBulkModel 	:	false,
			tableKey 		: 	getRandomString(),
        };

		this.anchorRef 				=	React.createRef();
		this.handleDeleteEmployee 	=	this.handleDeleteEmployee.bind(this);
		this.hideModal				= 	this.hideModal.bind(this);
    }

	componentDidMount() {
		this.getCookiesData();
    }

    /**
     * For get cookies details
     */
	getCookiesData = async () => {
        const cookies = new Cookies();
        const userCookie = cookies.get('user_details');
        const { department, location } = userCookie;

        let searchStats = {department: department ? department.map(dept => ({ value: dept, label: dept })) :null};
        if(location) searchStats.location = location;
        // this.setState({ searchStats: searchStats,  tableKey: getRandomString() });
        this.setState({   tableKey: getRandomString() });
    }

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type, searchStats }) {
        try{
			let apiReq = { model: 'home', method: 'masterData', name: inputValue ? inputValue : ''};
            if(type === "location") 	apiReq.type = "location";
            if(type === "job_title") 	apiReq.type = "job_title";

			/** Add page number */
            apiReq.current_page = page;

			if(type === "department"){
				let tmpLocations = searchStats && searchStats.location  ? searchStats.location  :[];
				let tmpDepartment = {};
				if(tmpLocations && tmpLocations.length){
					tmpLocations.forEach(element => {
						if(element.department && element.department.length){
							element.department.forEach(childKey => {
								DEPARTMENT_DROPDOWN.forEach(subElement=>{
									if(childKey  === subElement.value){
										tmpDepartment[subElement.value] = subElement;
									}
								});
							});
						}
					});
				}

				return {options: Object.values(tmpDepartment), hasMore: false, additional: {page: page, type: type }};
			}

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	hideModal = () => {
		this.setState({
			showBulkModel 	:	false,
			equipmentId		: 	"",
			tableKey		: 	getRandomString()
		});
	}

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}

	/** Function to handle delete button click */
	handleDeleteEmployee = (employeeId) => {
		const confirmation = customConfirmBox(translate('employee.you_want_to_delete_employee'));
		confirmation.then((confirmed) => {
			if(confirmed) {
				callBackendAPI([{ model: 'employee', method: 'deleteOne', employee_id: employeeId }]).then((response) => {
					if(response.success) {
						reFormatSuccess(response.data[0].message);
						this.setState({tableKey: getRandomString()});
					}
				}).catch(err => console.log(err));
			}
		});
	}

	customAction = (actionKey, rowData) => {
		if(actionKey === "bulk") this.setState({showBulkModel: true});
		if(actionKey === "add") this.props.history.push('/employee/add');
		if(actionKey === "edit" && rowData._id) this.props.history.push('/employee/update/' + rowData._id);
		if(actionKey === "delete" && rowData._id) this.handleDeleteEmployee(rowData._id)
	}

	/** Manage table custom render */
	customRender = (actionKey, rowData,completeApiRes) => {
		if(!actionKey || !rowData || !rowData._id)  return "";
		let element = "";
		if(actionKey === "department"){
			let department	= rowData.department ? rowData.department :[];
			DEPARTMENT_DROPDOWN.filter(data=>{
				if(department.indexOf(data.value)!== -1) element += ", " +data.label;
				return null;
			});
			element = element.slice(1);
		}
		if(actionKey === "status"){
			let status = (rowData.active)	? 	"Active" : "Deactive";
			element=<span className ={rowData.active ? "text-success" : "text-danger"}>
				{status}
			</span>
		}

		if(actionKey === "location" && rowData.location_title) element = rowData.location_title;
		if(actionKey === "assigned_vehicle" && rowData.assigned_vehicle) element = rowData.assigned_vehicle;
		if(actionKey === "image"){
			let completeImgUrl 	= completeApiRes.file_url 	? completeApiRes.file_url 	: "";
			let imgName 		= rowData.image 			? rowData.image 			: "";
			let employeeName	= upperCaseOfName(rowData.first_name ? rowData.first_name : "", rowData.last_name ? rowData.last_name : "");

			let nameHtml =<span className="sort-name greenbg">
				{employeeName}
			</span>;

			let employeeImg  = (imgName) ? completeImgUrl+imgName : "";
			element  		 = (imgName) ? <img src={employeeImg} alt="Img"  className="sort-name" onClick = {()=>{this.handleImageClick(employeeImg)}}/> : nameHtml;
		}

		return element;
	};// end customRender()


	render() {
		const {showBulkModel, lightBoxShow, selectedImage, tableKey, searchStats} = this.state;

		let actionButton = [{key: "edit"},{key: "delete"}];

		let pageStats = {
			searchStats :	searchStats,
			pageType :	"employee",
			title 		:	translate("employee.employee_list"),
			headerLinks	: [
				{name: translate("employee.add_employee"), 	selector: "back", clickEvent:()=> this.customAction('add') },
				{name: translate("employee.bulk_employee"),	selector: "bulk", clickEvent:()=> this.customAction('bulk') }
			],
			columns :	[
				{name: translate("employee.image"), 		selector: "image", customRender: this.customRender, width: "13%"},
				{name: translate("employee.employee_name"), selector: "full_name", width: "20%"},
				{name: translate("employee.job_title"), 	selector: "job_title", width: "20%"},
				{name: translate("employee.department"), 	selector: "department", customRender: this.customRender, width: "10%"},
				{name: translate("employee.location"), 		selector: "location", customRender: this.customRender, width: "15%"},
				{name: translate("employee.assigned_vehicle"), 		selector: "assigned_vehicle", customRender: this.customRender, width: "15%"},
				{name: translate("employee.status"), 		selector: "status", width: "5%",  customRender: this.customRender },
				// {name: translate("system.action"),		 	selector: "", width: "5%", custom_action: this.customAction, actions: actionButton},
			],
			search :	[
				{name: translate("employee.full_name"), 	selector: "full_name",  input_type: "text", 		 showLabel: true, inputClass: "input-with-label",},
				{name: translate("employee.location"), 	    selector: "location", 	input_type: "asyncPaginate", isMultiple: true, child_selector:"department"},
				{name: translate("employee.department"), 	selector: "department", input_type: "asyncPaginate", isMultiple: true, parent_selector:"location" },
				{name: translate("employee.job_title"), 	selector: "job_title",  input_type: "asyncPaginate"},
				{name: translate("employee.status"), 	    selector: "status", 	input_type: "select", showLabel:true, options: ACTIVE_DEACTIVE_DROPDOWN , defaultValue : {value : ACTIVE, label:"Active"} },
			],
		}

		if(Auth.getPermission() !== PROJECT_MANAGER) pageStats.columns.push({name: translate("system.action"), selector: "", width: "5%", custom_action: this.customAction, actions: actionButton});

		return <>
			<NormalDataTable key={tableKey}  pageStats={pageStats} apiExport={{model: 'employee', method: 'employeeExports',allRecords : true}} apiParm={{model: 'employee', method: 'list'}} getDropDownList={this.getDropDownList} />

			{showBulkModel ?
				<BulkEmployeeModal hideModal={this.hideModal} />
			:null}
			{
				lightBoxShow &&
				<Lightbox
					mainSrc={selectedImage}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
				/>
			}
		</>
	}
}
export default EmployeeList;
