import { IntlProvider } from "react-intl";
import messages_en from "./../locales/en.json";
import { DEFAULT_LANGUAGE_CODE, AM_PM_FORMAT_WITH_DATE }  from './../config/global_constants';
import moment from 'moment';

const messages = { 'en': messages_en };
const message	=	messages[DEFAULT_LANGUAGE_CODE];
const intlProvider = new IntlProvider({ locale: "en", defaulLocale: DEFAULT_LANGUAGE_CODE, messages: message });
const { intl } = intlProvider.getChildContext();
// const intl = IntlProvider({ locale: "en",   messages:  message })

/* translate the words */
export function translate(id, defaultMessage=null, constant){
	if(!defaultMessage) defaultMessage = id;
	let message = intl.formatMessage({id: id, defaultMessage: defaultMessage});
	if(constant && message && typeof constant === "string" && message.indexOf("%s") !== -1){
		message = message.replaceAll("%s",constant);
	}
	return message;
};

/* format the number/price in required format */
export function currency(amount, currency = "INR"){
	return intl.formatNumber(amount , {style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: 2});
};

/* format the date in required format */
export function formatDate(dateToBeFormat, format = AM_PM_FORMAT_WITH_DATE){
	return moment(dateToBeFormat).format(format);
};

/* format the date in required format */
export function timeAgo(date){
	return moment(new Date(date)).fromNow();
};

// Function to extract time without seconds and with AM/PM from ISODate string
export function formatTime(date, format= "HH:mm") {
    // Parse ISODate string using moment
    const parsedDate = moment(date);
    const formattedTime = parsedDate.format(format);
    return formattedTime;
}

export function getDatesBetween(startDate, endDate){
	let dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates.sort((a,b)=>{ return b-a});
}

