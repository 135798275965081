import React, { Component } from "react";

import { translate  } from './../helpers/intl_helpers';

let googleMapsScriptLoaded = false;

class GoogleMap extends Component {
	constructor(props) {
        super(props)

        this.state = {
			map		: 	null,
      		markers	:	[]
        };

		this.mapRef = React.createRef();
    }

	componentDidMount() {
		// Load the Google Maps script dynamically
		this.loadGoogleMapsScript();
	}

	componentWillUnmount() {
		// Clean up markers
		this.state.markers.forEach(marker => marker.setMap(null));
	}

	loadGoogleMapsScript(){
		if(googleMapsScriptLoaded) {
			this.initMap();
			return
		}

		//Create the google script element
		// const script =	document.createElement('script');
		// script.src 	 =	`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMap`;
		// script.async =	true;
		// script.defer =	true;
		// script.onload=	() => {
		//   	googleMapsScriptLoaded = true;
		//   	this.initMap();
		// };
		// Set the initMap function in the global window object
		googleMapsScriptLoaded = true;
		this.initMap();
		window.initMap = this.initMap;
		// document.head.appendChild(script);
	}

	initMap = () =>{
		//Initialize the map
		let mapInstance = new window.google.maps.Map(this.mapRef.current, {
			center: { lng: 47.08001, lat: -122.60321 },
			zoom: 10,
			mapTypeControl: false, // Hide map type control (satellite view option)
			streetViewControl: false, // Hide Street View control (Pegman)
		});

		this.setState({ map: mapInstance });
	};

	geocodeZipCode(zipCode, title) {
		const google = window.google;
		const geocoder = new google.maps.Geocoder();

		geocoder.geocode({ address: zipCode }, (results, status) => {
			if (status === 'OK'){
				const location = results[0].geometry.location;
				this.addMarker(location, title);
				this.state.map.setCenter(location);
			}
		});
	}

	addMarker(location, title) {
		const google = window.google;
		const marker = new google.maps.Marker({
			position: location,
			map		: this.state.map,
			title	: title ? String(title) :""
		});

		this.setState((prevState) => ({
		  	markers: [...prevState.markers, marker]
		}));
	}

	markerInitiated() {
		if(this.state.markers && this.state.markers.length){
			this.state.markers.forEach((marker) => marker.setMap(null));
		}

		this.setState({ markers: [] }, ()=>{
			if(this.props.dataList && this.props.dataList.length){
				this.props.dataList.forEach(element=>{
					this.geocodeZipCode(element._id, translate("map.total_jobs")+" - "+element.count)
				});
			}
		});
	}

	render() {
		return (
			<div className="row">
				<div className="col-12">
					<div ref={this.mapRef} id="googleMapSection" className='w-100 h-500'></div>
				</div>
			</div>
		);
	}
}
export default GoogleMap;