import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import { Scrollbars } from 'rc-scrollbars';
import { withRouter } from 'react-router-dom';
import Auth from '../helpers/Auth';

import { translate  } from './../helpers/intl_helpers';
import closeIcon from '../assets/images/close_icon.svg';
import { ADMIN, PROJECT_MANAGER, DISPATCHER, GENERIC_TV_LOGINS} from './../config/global_constants';

class LeftMenu extends Component {

	render() {

		return (
			<div className="side-menu">
				<div className="side-menu-header ">
					<Link to="/">
						<img src="/images/logo.png" alt="Logo" width={137} height={82} />
					</Link>
					<button className="menu-btn side-bar-close"><img src={closeIcon} alt="img" /></button>
				</div>
				<div className="side-menu-heading">{translate("left_menu.main_menu")}</div>
				<div className="side-menu-list">
					<Scrollbars universal autoHide >
						<ul className="accordion" id="accordionExample">
							{([ADMIN, PROJECT_MANAGER, DISPATCHER].indexOf(Auth.getPermission()) !== -1) && 
								<li>
									<Link to="/dashboard" className={this.props.location.pathname.indexOf("/dashboard") !==-1 ? "active" :""}>
										<img src="/images/dashboard-icon.svg" alt="Dashboard" width={23} height={23} />
										{translate("left_menu.dashboard")}
									</Link>
								</li>
							}
							<li>
								<Link to='/calendar' className={this.props.location.pathname.indexOf("/calendar") !==-1 ? "active" :""}>
									<img src="/images/calendar_icon.svg" alt="calendar" width={23} height={23} />
									{translate("left_menu.calendar")}
								</Link>
							</li>
							{([GENERIC_TV_LOGINS].indexOf(Auth.getPermission()) !== 0) && 
								<>
									<li>
										<Link to='/job-map' className={this.props.location.pathname.indexOf("/job-map") !==-1 ? "active" :""}>
											<img src="/images/google-map.svg" alt="Users" width={23} height={23} />
											{translate("left_menu.job-map")}
										</Link>
									</li>
									<li>
										<Link to='/locate-tickets' className={this.props.location.pathname.indexOf("/locate-tickets") !==-1 ? "active" :""}>
											<img src="/images/locate-tickets.svg" alt="calendar" width={23} height={23} />
											{translate("left_menu.locate_tickets")}
										</Link>
									</li>
								</>
							}
							{([ADMIN, PROJECT_MANAGER, DISPATCHER].indexOf(Auth.getPermission()) !== -1) && 
								<li>
									<Link to='/employee' className={this.props.location.pathname.indexOf("/employee") !==-1 ? "active" :""}>
										<img src="/images/employee_icon.svg" alt="Users" width={23} height={23} />
										{translate("left_menu.employees")}
									</Link>
								</li>
							}
							{([ADMIN, DISPATCHER].indexOf(Auth.getPermission()) !== -1) && 
								<li>
									<Link to='/equipments' className={this.props.location.pathname.indexOf("/equipments") !==-1 ? "active" :""}>
										<img src="/images/equipments.svg" alt="Users" width={23} height={23} />
										{translate("left_menu.equipments")}
									</Link>
								</li>
							}
							{([ADMIN, PROJECT_MANAGER, DISPATCHER].indexOf(Auth.getPermission()) !== -1) && 
								<li>
									<Link to='/sub-contractor'  className={this.props.location.pathname.indexOf("/sub-contractor") !==-1 ? "active" :""}>
										<img src="/images/sub-contractor.svg" alt="Users" width={23} height={23} />
										{translate("left_menu.sub_contractor")}
									</Link>
								</li>
							}

							{([ADMIN, PROJECT_MANAGER, DISPATCHER].indexOf(Auth.getPermission()) !== -1) && 
								<li>
									<Link to='/customer' className={this.props.location.pathname.indexOf("/customer") !==-1 ? "active" :""}>
										<img src="/images/customer_icon.svg" alt="calendar" width={23} height={23} />
										{translate("left_menu.customers")}
									</Link>
								</li>
							}

							{([ADMIN, PROJECT_MANAGER, DISPATCHER].indexOf(Auth.getPermission()) !== -1) && 
								<li>
									<Link to="#" type="button" className={"collapse-nav "+(this.props.location.pathname.indexOf("/reports/") === -1 ? "collapsed" :"")} data-bs-toggle="collapse" data-bs-target="#leftMenuCollapseTwo" aria-expanded="false" aria-controls="leftMenuCollapseTwo">
										<img src="/images/task.svg" alt="Dashboard" width="23" height="23" />
										{translate("left_menu.reports")}
									</Link>
									<div id="leftMenuCollapseTwo" className={"accordion-collapse collapse "+(this.props.location.pathname.indexOf("/reports/") !==-1 ? "show" :"")}  data-bs-parent="#accordionExample">
										<div className="accordion-body">
											<ul>
												<li>
													<Link to="/reports/job-list" className={this.props.location.pathname.indexOf("/reports/job-list") !==-1 ? "active" :""}>
														{translate("left_menu.complete_jobs_report")}
													</Link>
												</li>
												<li>
													<Link to="/reports/jobs-lookup" className={this.props.location.pathname.indexOf("/reports/jobs-lookup") !==-1 ? "active" :""}>
														{translate("left_menu.jobs_lookup")}
													</Link>
												</li>
												<li>
													<Link to="/reports/resources-availability" className={this.props.location.pathname.indexOf("/reports/resources-availability") !==-1 ? "active" :""}>
														{translate("left_menu.resources_availability")}
													</Link>
												</li>
												<li>
													<Link to="/reports/timeoff-report" className={this.props.location.pathname.indexOf("/reports/timeoff-report") !==-1 ? "active" :""}>
														{translate("left_menu.timeoff_report")}
													</Link>
												</li>
												<li>
													<Link to="/reports/equipment-utilization" className={this.props.location.pathname.indexOf("/reports/equipment-utilization") !==-1 ? "active" :""}>
														{translate("left_menu.equipment_utilization")}
													</Link>
												</li>
												<li>
													<Link to="/reports/subcontractor" className={this.props.location.pathname.indexOf("/reports/subcontractor") !==-1 ? "active" :""}>
														{translate("left_menu.subcontractor_report")}
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</li>
							}
							{([ADMIN, PROJECT_MANAGER].indexOf(Auth.getPermission()) !== -1) &&
								<li>
									<Link to="#" type="button" className={"collapse-nav "+(this.props.location.pathname.indexOf("/master/") === -1 ? "collapsed" :"")} data-bs-toggle="collapse" data-bs-target="#leftMenuCollapseOne" aria-expanded="false" aria-controls="leftMenuCollapseOne">
										<img src="/images/master-management.svg" alt="Dashboard" width="23" height="23" />
										{translate("left_menu.control_panel")}
									</Link>
									<div id="leftMenuCollapseOne" className={"accordion-collapse collapse "+(this.props.location.pathname.indexOf("/master/") !==-1 ? "show" :"")}  data-bs-parent="#accordionExample">
										<div className="accordion-body">
											<ul>
												<li>
													<Link to="/master/driver_tier_level" className={this.props.location.pathname.indexOf("/master/driver_tier_level") !==-1 ? "active" :""}>
														{translate("master.driver_tier_level")}
													</Link>
												</li>
												<li>
													<Link to="/master/equipment_tier_level" className={this.props.location.pathname.indexOf("/master/equipment_tier_level") !==-1 ? "active" :""}>
														{translate("master.equipment_tier_level")}
													</Link>
												</li>
												<li>
													<Link to="/master/fiber_task" className={this.props.location.pathname.indexOf("/master/fiber_task") !==-1 ? "active" :""}>
														{translate("master.fiber_task")}
													</Link>
												</li>
												<li>
													<Link to="/master/hdd_drill_size" className={this.props.location.pathname.indexOf("/master/hdd_drill_size") !==-1 ? "active" :""}>
														{translate("master.hdd_drill_size")}
													</Link>
												</li>
												<li>
													<Link to="/master/headend_hub_location" className={this.props.location.pathname.indexOf("/master/headend_hub_location") !==-1 ? "active" :""}>
														{translate("master.headend_hub_location")}
													</Link>
												</li>
												<li>
													<Link to="/master/job_title" className={this.props.location.pathname.indexOf("/master/job_title") !==-1 ? "active" :""}>
														{translate("master.job_title")}
													</Link>
												</li>
												<li>
													<Link to="/master/location" className={this.props.location.pathname.indexOf("/master/location") !==-1 ? "active" :""}>
														{translate("master.location")}
													</Link>
												</li>
												<li>
													<Link to="/master/specialized_tools_ctr" className={this.props.location.pathname.indexOf("/master/specialized_tools_ctr") !==-1 ? "active" :""}>
														{translate("master.specialized_tools_ctr")}
													</Link>
												</li>
												<li>
													<Link to="/master/badges" className={this.props.location.pathname.indexOf("/master/badges") !==-1 ? "active" :""}>
														{translate("master.badges")}
													</Link>
												</li>
												<li>
													<Link to="/master/scope_of_work_type" className={this.props.location.pathname.indexOf("/master/scope_of_work_type") !==-1 ? "active" :""}>
														{translate("master.scope_of_work_type")}
													</Link>
												</li>
												<li>
													<Link to="/master/hdd_locator_type" className={this.props.location.pathname.indexOf("/master/hdd_locator_type") !==-1 ? "active" :""}>
														{translate("master.hdd_locator_type")}
													</Link>
												</li>
												<li>
													<Link to="/master/surface_type" className={this.props.location.pathname.indexOf("/master/surface_type") !==-1 ? "active" :""}>
														{translate("master.surface_type")}
													</Link>
												</li>
												<li>
													<Link to="/master/locate_type" className={this.props.location.pathname.indexOf("/master/locate_type") !==-1 ? "active" :""}>
														{translate("master.locate_type")}
													</Link>
												</li>
												<li>
													<Link to="/department-cost" className={this.props.location.pathname.indexOf("/department-cost") !==-1 ? "active" :""}>
													{translate("left_menu.department_cost")}

													</Link>
												</li>
												{(Auth.getPermission() === ADMIN) && 
													<li>
														<Link to='/work-order' className={this.props.location.pathname.indexOf("/work-order") !==-1 ? "active" :""}>
															{translate("left_menu.work_orders")}
														</Link>
													</li>
												}
												<li>
													<Link to="/master/skill_set" className={this.props.location.pathname.indexOf("/master/skill_set") !==-1 ? "active" :""}>
													{translate("left_menu.skill_set")}

													</Link>
												</li>
											</ul>
										</div>
									</div>
								</li>
							}
						</ul>
					</Scrollbars>
				</div>
			</div>
		);
	}
}
export default withRouter(LeftMenu);