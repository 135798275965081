
import  './../assets/css/style.css';
import  './../assets/css/developer.css';
import 'react-toastify/dist/ReactToastify.css';

import React, {Component} from 'react';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

import { UserDetails }  from '../UserDetails';

toast.configure({ autoClose: 8000, draggable: false });

export default class Layout extends Component {

    constructor(props) {
        super(props);
        const cookies = new Cookies();
        this.state = {
			user_details : (cookies.get('user_details') || {}),
		}
	}

    updateUserState = (value, cb) => {
		this.setState({user_details : value},()=>{
            if(cb) cb();
        });
	};

    render() {
		const childrenWithProps = React.Children.map(this.props.children, child =>
			React.cloneElement(child)
		);
        return (
			<UserDetails.Provider value={{ userDetails: this.state.user_details, updateUserState: this.updateUserState}}>
                <div className="login-page">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="login-left">
                                    <figure><img src="/images/logo.png" alt="Logo" width={300} height={180} /></figure>
                                    <p className="text-center">“Cannon BUILDS Communication and Power Infrastructure THAT Accelerates Client Value by Serving them with Distinction ACROSS a Platform of Specialized Utility Construction Services.”</p>
                                </div>
                            </div>
                            <div className="col-lg-6 ps-lg-0">
                                { childrenWithProps }
                            </div>
                        </div>
                    </div>
                </div>
            </UserDetails.Provider>
        )
    }
}