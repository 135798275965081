import React, {Component} from 'react'
import {PropTypes} from 'prop-types'

class TaskItem extends Component
{

    static propTypes = {
        task: PropTypes.object.isRequired,
    }

    render(){
        const {task, isDragging, connectDragSource, connectDragPreview} = this.props;
        let dragContent = <li style={{ backgroundColor: task.state.bgColor }}>
             <img src={task.state.image}  alt="" />
            {task.name}
            </li>;

        return (
            isDragging ? null : (
                
                        connectDragPreview(
                            connectDragSource(dragContent)
                        )
            )
        )
    }
}

export default TaskItem
