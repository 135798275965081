import React, { Component } from "react";
import { NavLink as Link} from "react-router-dom";

import { translate } from './../helpers/intl_helpers';

class Page404 extends Component {
	render() {
		return (
			<div className="login-page 404-page">
				<div className="container">
					<div className="row text-center">
						<div className="logo">
							<figure>
								<img src="/images/404.png" alt="logo"/>
							</figure>
						</div>
						{/* <h2>{translate("system.page_not_exists","Oops. The page you're looking for doesn't exist.")}</h2> */}
						<Link to="/">
							<button type="button" className="btn btn-fill mt-3">{translate("system.go_to_home_page","Go To Home Page")}</button>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
export default Page404;
