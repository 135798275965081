import '@availity/block-ui/src/BlockUi.css';
import 'react-image-lightbox/style.css';

import React, { Component } from "react";
import BlockUi from '@availity/block-ui';
import Lightbox from 'react-image-lightbox';
import { NavLink as Link } from "react-router-dom";
import {AsyncPaginate} from 'react-select-async-paginate';
import Select, { components } from 'react-select';

import CropImageComponent from './../../elements/CropImage/CropImageComponent';
import { callBackendAPI, handleFormValidation,callBackendAPIAndImageUpload,reFormatErrors,reFormatSuccess,handleFormInputs} from './../../helpers/common';
import {STATUS_SUCCESS, DEPARTMENT_DROPDOWN,RADIO_YES, RADIO_NO}  from './../../config/global_constants';
import { translate  } from './../../helpers/intl_helpers';

const customStyles = {
    menu: base => ({
      ...base,
      top: 'unset', // Reset the 'top' property
      bottom: '100%', // Position the dropdown above the select input
      marginTop: '-1px' // Add a slight offset to avoid overlap
    })
};

const customComponents = {
    Menu: props => (
      <components.Menu {...props} />
    )
};

export default class EditEquipment extends Component {
	constructor(props) {
        super(props)

        this.state = {
			isLoading		: 	true,
			errors			: 	{},
			fields: {
				color_code : {value: "#ffffff"},
				is_rental  : {value: String(RADIO_NO)}
			},
			submitted		: 	false,
			fileUrl 		:	"",
			equipmentImgUrl :	"",
			equipmentNoImgUrl:	"",
			imageSrc		:	"",
			croppedImage	: 	"",
			lightBoxShow	: 	false,
			equipment_code 	: 	"",
			locationList		: 	[],
        };

		this.onCropComplete					 =  this.onCropComplete.bind(this);
		this.handleChange					 =  this.handleChange.bind(this);
		this.handleFormInputs 				 =	this.handleFormInputs.bind(this);
		this.fetchEquipmentDetails			 =  this.fetchEquipmentDetails.bind(this);
		this.handleUpdateEquipmentFormSubmit =  this.handleUpdateEquipmentFormSubmit.bind(this);
		this.loadDropDownOptions 		     =  this.loadDropDownOptions.bind(this);
    }

	/** For get equipment details  */
	componentDidMount() {
		const { equipment_id } = this.props.match.params ? this.props.match.params : {};
		this.fetchEquipmentDetails(equipment_id);

		
		/** Set API request params */
		let apiReq = [{ model: 'equipment', method: 'equipmentData'},{ model: 'home', method: 'masterList' , type: ["location"]}]
		callBackendAPI(apiReq).then(response => {
			console.log("response.data[0]",response)
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				let equipmentType = response.data[0].result;		
				this.setState({
					equipment_type : equipmentType,
					locationList   : response.data[1].result.location,
				});
			}
		}).catch(err => console.log(err));
    }

	componentDidUpdate(prevProps) {
		const { equipment_id } = this.props.match.params ? this.props.match.params : {};

		if (equipment_id !== prevProps.match.params.equipment_id) {
			this.fetchEquipmentDetails(equipment_id);
		}
	}

	/**
	 *  For get equipment details
	 *
	 * @param {*} id as equipment id
	 */
	fetchEquipmentDetails(equipmentId){
		if(equipmentId){
			/** Update loading stats */
			this.setState({ isLoading : true},()=>{

				/** Get equipment details */
				callBackendAPI([{model: 'equipment', method: 'equipmentDetails', equipment_id: equipmentId },{ model: 'home', method: 'masterList', type: ["location"] }]).then(response =>{
					if(response.success && response.data[0].status === STATUS_SUCCESS) {
						let tmpStats = {isLoading: false};

						/** Set stats */
						let eqDetails 				=	response.data[0].result;
						tmpStats.equipmentImgUrl 	= 	response.data[0].img_url 	? 	response.data[0].img_url	: "";
						tmpStats.equipmentNoImgUrl 	=	response.data[0].no_img_url ?	response.data[0].no_img_url	: "";
						let locationList = response.data[1].result && response.data[1].result.location ? response.data[1].result.location :[];

						const addedDepartments = new Set();
						let userDeprt 	= eqDetails.department ? eqDetails.department :[];
						let lctData 	= eqDetails.location ? eqDetails.location :[];
						let userDeprtList = [];
						let userLocationList = [];
						if(locationList.length && userDeprt.length){
							locationList.forEach(element=>{
								if(lctData.indexOf(element.value) !== -1){
									userLocationList.push({value: element.value ,label: element.label, department: element.department});

									element.department.forEach(item=>{
										if(userDeprt.indexOf(item) !== -1){
											let matchDepartment = DEPARTMENT_DROPDOWN.find(dept => dept.value === item);
											if (matchDepartment) {
												// Check if the department has already been added
												if (!addedDepartments.has(item)) {
													userDeprtList.push(matchDepartment);
													addedDepartments.add(item); // Mark the department as added
												}
											}
										}
									});
								}
							});
						}

						tmpStats.fields = {
							location_id			: 	userLocationList,
							department_id		: 	userDeprtList,
							gvw_class			:	{value: eqDetails.gvw_class},
							tier_level			:	{value: eqDetails.tier_level, label: eqDetails.tier_level_title},
							driver_owner_id		:	(eqDetails.driver_owner_id && eqDetails.driver_owner_title ) ? {value: eqDetails.driver_owner_id, label : eqDetails.driver_owner_title} :null,
							equipment_img		:	eqDetails.image ? {value: eqDetails.image} :null,
							equipment_code		:	{value: eqDetails.equipment_code},
							equipment_make		:	{value: eqDetails.equipment_make},
							equipment_model		:	{value: eqDetails.equipment_model},
							equipment_status	:	{value: eqDetails.equipment_status},
							equipment_type		:	{value: eqDetails.equipment_type, label: eqDetails.equipment_type},
							company_code		:	{value: eqDetails.company_code},
							description			:	{value: eqDetails.description},
							color_code			:	{value: eqDetails.color_code},
							year				:	{value: eqDetails.year},
							serial_number		:	{value: eqDetails.serial_number},
							license_number		:	{value: eqDetails.license_number},
							is_rental			:   {value: String(eqDetails.is_rental) || "" },
						};

						/** Update stats */
						this.setState(tmpStats);
					}else{
						/** Redirect to equipment page */
						this.props.history.push('/equipments');
					}
				}).catch(err => console.log(err));
			});
		}else{
			/** Update stats */
			this.setState({isLoading : false});
		}
	}//fetchEquipmentDetails

	/**
	* For load dropdown options
	*
	* @param inputValue		as 	search term
	* @param loadedOptions	as 	old options array
	* @param page			as 	page count
	*
	* @return json
	*/
	async loadDropDownOptions(inputValue, loadedOptions, { page, type }) {
		try{
			let apiReq = { model: 'home', method: 'masterData', name: inputValue ? inputValue : '', current_page: page};
			if(type === "location") apiReq.type = "location";
			if(type === "tier_level") apiReq.type = "equipment_tier_level";
			if(type === "driver_owner") apiReq = { model: 'calendar', method: 'employeeList', is_crew_member: true, emp_name: inputValue ? inputValue : '', current_page: page};

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);
			return {
				options		:	result,
				hasMore		:	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}

		} catch (error) {
			console.error('Error loading options:', error);
			return {
				options		: 	[],
				hasMore		:	false,
				additional	: 	{page: page, type: type},
			};
		}
	}// end loadDropDownOptions()

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleUpdateEquipmentFormSubmit(e){
		e.preventDefault();

		const { equipment_id } = this.props.match.params ? this.props.match.params : {};
		const {location_id,department_id,gvw_class,tier_level,driver_owner_id, equipment_img,equipment_code,equipment_make,equipment_model,equipment_status,equipment_type,company_code,description,color_code, image, year, serial_number, license_number, is_rental} = this.state.fields;

		let locationArr   = [];
		let departmentArr   = [];
		if (location_id && location_id.length) locationArr = location_id.map(element => element.value);
		if (department_id && department_id.length) departmentArr = department_id.map(element => element.value);

		this.setState({
			submitted: true,
			fields : {
                ...this.state.fields,
                ...{
                    location: {value: locationArr.length ? locationArr : null},
                    department: {value: departmentArr.length ? departmentArr : null},
                }
            }
		},()=>{

			/** Api Requested Data **/
			let params = [{
				model			: 	'equipment',
				method			: 	'addEquipments',
				equipment_id	: 	equipment_id ? equipment_id :"",
				location		: 	locationArr,
				department		: 	departmentArr,
				gvw_class		: 	(gvw_class && gvw_class.value)				?	gvw_class.value			:"",
				tier_level		: 	(tier_level && tier_level.value)			?	tier_level.value		:"",
				driver_owner_id : 	(driver_owner_id && driver_owner_id.value) 	? 	driver_owner_id.value 	:"",
				old_image		: 	(equipment_img && equipment_img.value)		?	equipment_img.value		:"",
				equipment_code	: 	(equipment_code && equipment_code.value)	?	equipment_code.value	:"",
				equipment_make	: 	(equipment_make && equipment_make.value)	?	equipment_make.value	:"",
				equipment_model	: 	(equipment_model && equipment_model.value)	?	equipment_model.value	:"",
				equipment_status: 	(equipment_status && equipment_status.value)?	equipment_status.value	:"",
				equipment_type	: 	(equipment_type && equipment_type.value)	?	equipment_type.value	:"",
				company_code	: 	(company_code && company_code.value)		?	company_code.value		:"",
				description		: 	(description && description.value)			?	description.value		:"",
				color_code		: 	(color_code && color_code.value)			?	color_code.value		:"",
				year			: 	(year && year.value)						?	year.value				:"",
				serial_number	: 	(serial_number && serial_number.value)		?	serial_number.value		:"",
				license_number	: 	(license_number && license_number.value)	?	license_number.value	:"",
				is_rental		: 	(is_rental && is_rental.value)				?	is_rental.value	:"",
			}];

			/** Validation Rules **/
			let validations = {
				location		: {label: translate('equipments.location'),   "rules" :["select"]},
				department		: {label: translate('equipments.department'), "rules" :["select"]},
				tier_level		: {label: translate('equipments.tier_level'), "rules" :["select"]},
				// gvw_class		: {label: translate('equipments.gvw_class'),  "rules" :["required"]},
				equipment_code	: {label: translate('equipments.equipment_code'),  "rules" :["required"]},
				equipment_make	: {label: translate('equipments.equipment_make'),  "rules" :["required"]},
				equipment_model	: {label: translate('equipments.equipment_model'),  "rules" :["required"]},
				equipment_status: {label: translate('equipments.equipment_status'),	"rules" :["required"]},
				equipment_type	: {label: translate('equipments.equipment_type'),  "rules" :["select"]},
				company_code	: {label: translate('equipments.company_code'),  "rules" :["required"]},
				description		: {label: translate('equipments.description'),  "rules" :["required"]},
				color_code		: {label: translate('equipments.color_code'),  "rules" :["required"]},
				year			: {label: translate('equipments.year'),  "rules" :["required"]},
				serial_number	: {label: translate('equipments.serial_number'),  "rules" :["required"]}
			}

			if(!this.state.croppedImage && (!equipment_img || !equipment_img.value)){
				// validations["image"] = {"label": translate('equipments.image'), "rules": ["select"]};
			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params, false).then(async (response) =>{
				if(!response.success) return this.setState({ errors : response.errors, submitted: false});

				/** Set form data */
				let formData = new FormData();
				if(this.state.croppedImage) formData.append("image", this.state.croppedImage, (image && image.file_name ? image.file_name.split(".")[0] :'croppedImage')+'.jpg');

				/** Submit form to server */
				let responseData = await callBackendAPIAndImageUpload({ model: 'equipment', method: 'addEquipments', fileData: formData, formData: params[0]}, true);
				if (responseData.data.status === STATUS_SUCCESS) {
					/** Show success flash message **/
					reFormatSuccess(responseData.data.message[0].msg);

					/** Remove disable attribute from submit button */
					this.setState({ errors: {} , fields: {} , submitted: false});

					/** Redirect to equipment page  */
					this.props.history.push('/equipments');

				}else{
					/** Remove disable attribute from submit button */
					this.setState({submitted: false , errors: reFormatErrors(responseData.data.message)});
				}
			}).catch(err => console.log(err));
		});
	}// end handleUpdateEquipmentFormSubmit()

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response = handleFormInputs(e, field, this.state.fields);
		this.setState({ response });

		if(field === "image"){
			this.setState({croppedImage : ""})
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				this.setState({ imageSrc: reader.result, croppedImage: "" });
			});
			reader.readAsDataURL(file);
		}
	}// end handleFormInputs()

	/**Handle dropdown value state */
	handleChange = (fieldName) => (fieldVal) => {
		let updatedFields = {...this.state.fields, [fieldName]: fieldVal};

		if(fieldName === "location_id" && fieldVal.length===0) updatedFields['department_id'] = null;

		this.setState({fields: updatedFields});
	}; // end handleChange()

	onCropComplete = (croppedImage) => {
		this.setState({ croppedImage });
	};

	render() {
		const { equipment_id } = this.props.match.params ? this.props.match.params : {};
		const {submitted, fields, errors, isLoading, imageSrc, croppedImage, equipmentImgUrl, equipmentNoImgUrl, lightBoxShow,equipment_type, locationList} = this.state;
		return <>
			<BlockUi tag="div" blocking={isLoading}>
				<div className="dashboard-content">
					<div className="dashboard-heading d-flex justify-content-between align-items-center heading-back">
						<div className="left-heading">
							<h1>{ equipment_id ? translate("equipments.update_equipment") :translate("equipments.add_equipment") }</h1>
						</div>
						<div className="right-heading">
							<Link to="/equipments" className={"btn btn-fill"}>
								{translate('system.back')}
							</Link>
						</div>
					</div>
					<div className="add-employee">
						<form className="adduser-form"  onSubmit={(event) => this.handleUpdateEquipmentFormSubmit(event, 'updateEquipment')} noValidate>
							<div className="row">
								<div className="col-lg-4">
									<div className="form-floating">
										<input id="equipment_code" type="text" className="form-control" placeholder={translate("equipments.equipment_code")}  onChange={(event) => this.handleFormInputs(event, "equipment_code")} value={fields.equipment_code && fields.equipment_code.value ? fields.equipment_code.value :""}  />
										<label htmlFor="equipment_code">{translate("equipments.equipment_code")}<span className="error text-danger">*</span></label>
										<span className="error">{errors["equipment_code"]}</span>
									</div>
								</div>
								<div className='col-lg-4 mb-4'>
									<div className="custom-form-group form-group">
										<Select
											className="custom-react-select"
											placeholder={<label htmlFor="equipment_type">{translate("equipments.select_equipment_type")}<span className="error text-danger">*</span></label>}
											options	={equipment_type ? equipment_type :null}
											value	={fields.equipment_type ? fields.equipment_type :null}
											closeMenuOnSelect={true}
											onChange={this.handleChange("equipment_type")}
											components={customComponents}
										/>
									</div>
									<span className="error">{errors["equipment_type"]}</span>
								</div>

								<div className="col-lg-4">
									<div className="form-floating">
										<input id="equipment_status" type="text" className="form-control" placeholder={translate("equipments.equipment_status")} onChange={(event) => this.handleFormInputs(event, "equipment_status")} value={fields.equipment_status && fields.equipment_status.value ? fields.equipment_status.value :""} />
										<label htmlFor="equipment_status">{translate("equipments.equipment_status")}<span className="error text-danger">*</span></label>
										<span className="error">{errors["equipment_status"]}</span>

									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-4">
									<div className="form-floating">
										<input id="company_code" type="text" className="form-control" placeholder={translate("equipments.company_code")} onChange={(event) => this.handleFormInputs(event, "company_code")} value={fields.company_code && fields.company_code.value ? fields.company_code.value :""}  />
										<label htmlFor="company_code">{translate("equipments.company_code")}<span className="error text-danger">*</span></label>
										<span className="error">{errors["company_code"]}</span>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="form-floating">
										<input id="equipment_make" type="text" className="form-control" placeholder={translate("equipments.equipment_make")} onChange={(event) => this.handleFormInputs(event, "equipment_make")} value={fields.equipment_make && fields.equipment_make.value ? fields.equipment_make.value :""} />
										<label htmlFor="equipment_make">{translate("equipments.equipment_make")}<span className="error text-danger">*</span></label>
										<span className="error">{errors["equipment_make"]}</span>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="form-floating">
										<input id="equipment_model" type="text" className="form-control" placeholder={translate("equipments.equipment_model")} onChange={(event) => this.handleFormInputs(event, "equipment_model")} value={fields.equipment_model && fields.equipment_model.value ? fields.equipment_model.value :""} />
										<label htmlFor="equipment_model">{translate("equipments.equipment_model")}<span className="error text-danger">*</span></label>
										<span className="error">{errors["equipment_model"]}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-4">
									<div className="form-floating">
										<input id="year" type="text" className="form-control" placeholder={translate("equipments.year")} onChange={(event) => this.handleFormInputs(event, "year")} value={fields.year && fields.year.value ? fields.year.value :""} />
										<label htmlFor="year">{translate("equipments.year")}<span className="error text-danger">*</span></label>
										<span className="error">{errors["year"]}</span>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="form-floating">
										<input id="serial_number" type="text" className="form-control" placeholder={translate("equipments.serial_number")} onChange={(event) => this.handleFormInputs(event, "serial_number")} value={fields.serial_number && fields.serial_number.value ? fields.serial_number.value :""} />
										<label htmlFor="serial_number">{translate("equipments.serial_number")}<span className="error text-danger">*</span></label>
										<span className="error">{errors["serial_number"]}</span>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="form-floating">
										<input id="license_number" type="text" className="form-control" placeholder={translate("equipments.license_number")} onChange={(event) => this.handleFormInputs(event, "license_number")} value={fields.license_number && fields.license_number.value ? fields.license_number.value :""} />
										<label htmlFor="license_number">{translate("equipments.license_number")}</label>
										<span className="error">{errors["license_number"]}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-4">
									<div className="form-floating">
										<input
											id="color_code"
											type="color"
											className="form-control"
											placeholder={translate("master.master_color_code")}
											value={fields["color_code"] ? fields["color_code"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "color_code")}
										/>
										<label htmlFor="color_code">{translate("master.master_color_code")}<span className="error text-danger">*</span></label>
										<span className="error text-danger">{errors["color_code"]}</span>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="form-floating">
										<input id="description" type="text" className="form-control" placeholder={translate("equipments.description")} onChange={(event) => this.handleFormInputs(event, "description")} value={fields.description && fields.description.value ? fields.description.value :""} />
										<label htmlFor="description">{translate("equipments.description")}<span className="error text-danger">*</span></label>
										<span className="error">{errors["description"]}</span>

									</div>
								</div>
								<div className='col-lg-4'>
									<div className="form-floating">
										<input id="gvw_class" type="text" className="form-control" placeholder={translate("equipments.gvw_class")} value={fields.gvw_class && fields.gvw_class.value ? fields.gvw_class.value :""} onChange={(event) => this.handleFormInputs(event, "gvw_class")} />
										<label htmlFor="gvw_class">{translate("equipments.gvw_class")}<span className="error text-danger">*</span></label>
										<span className="error">{errors["gvw_class"]}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className='col-lg-4 mb-4'>
									<div className="form-group custom-upload">
										<label className="upload-source">
											<input type="file" name='file' id="file" className="d-none" accept=".png,.jpg,.jpeg" onChange={(event) => this.handleFormInputs(event, "image")} />
											<p>
												{fields.image && fields.image.file_name
												?
													fields.image.file_name
												:
													<>
														{translate("system.select_image")}
														{!equipment_id ? <i className="error text-danger">*</i>:""}

													</>
												}
											</p>
											<span>{translate("system.upload")}</span>
										</label>
										{equipmentImgUrl && fields.equipment_img && fields.equipment_img.value ?
											<>
												<button type="button" className="recent-img-box" onClick={()=>{ this.setState({lightBoxShow:true })}}>
													<img src={equipmentImgUrl+fields.equipment_img.value} alt="equipment" onError={(event) => event.target.src = equipmentNoImgUrl } />
												</button>

											</>
										:null}
									</div>
									<span className="error">{errors["image"]}</span>

									{imageSrc && !croppedImage && (
										<div className="comman-crop-popup">
											<CropImageComponent
												imageSrc={imageSrc}
												onCropComplete={this.onCropComplete}
											/>
										</div>
									)}
								</div>
								<div className='col-lg-4 mb-4'>
									<div className="custom-form-group form-group">
										<Select
											className="custom-react-select"
											placeholder={<label htmlFor="location">{translate("employee.location")}<span className="error text-danger">*</span></label>}
											options={locationList}
											value={fields.location_id ? fields.location_id : null}
											closeMenuOnSelect={true}
											isMulti
											onChange={this.handleChange("location_id")}
										/>
									</div>
									<span className="error">{errors["location"]}</span>
								</div>
								<div className='col-lg-4 mb-4'>
									<div className="custom-form-group form-group">
										<Select
											className="custom-react-select"
											value={fields.department_id ? fields.department_id : null}
											// options={(fields.location && fields.location.department) ? DEPARTMENT_DROPDOWN.filter(department => fields.location.department.includes(department.value)) : []}
											options={(fields.location_id) ? DEPARTMENT_DROPDOWN.filter(dept => fields.location_id && fields.location_id.some(loc => loc.department.includes(dept.value))) : []}
											onChange={this.handleChange("department_id")}
											closeMenuOnSelect={true}
											isMulti
											placeholder={<label htmlFor="department">{translate("employee.department")}<span className="error text-danger">*</span></label>}
										/>
										{/* <Select
											className="custom-react-select"
											placeholder={<label htmlFor="department">{translate("equipments.select_department")}<span className="error text-danger">*</span></label>}
											options={(fields.location && fields.location.department) ? DEPARTMENT_DROPDOWN.filter(department => fields.location.department.includes(department.value)) : []}
											value={fields.department ? fields.department :null}
											closeMenuOnSelect={true}
											onChange={this.handleChange("department")}
											styles={customStyles}
											components={customComponents}
											isMulti
										/> */}
									</div>
									<span className="error">{errors["department"]}</span>
								</div>
							</div>
							<div className="row">
								<div className='col-lg-4 mb-4'>
									<div className="custom-form-group form-group">
										<AsyncPaginate
											className="custom-react-select"
											value={(fields.tier_level && fields.tier_level.value) ? fields.tier_level : null}
											loadOptions={this.loadDropDownOptions}
											onChange={this.handleChange("tier_level")}
											placeholder={<label htmlFor="tier_level">{translate("equipments.select_tier_level")}<span className="error text-danger">*</span></label>}
											additional={{ page: 1, type: "tier_level" }}
											styles={customStyles}
											isClearable={true}
											components={customComponents}
										/>
									</div>
									<span className="error">{errors["tier_level"]}</span>
								</div>
								<div className='col-lg-4 mb-4'>
									<div className="custom-form-group form-group">
										<AsyncPaginate
											className="custom-react-select"
											value={(fields.driver_owner_id && fields.driver_owner_id.value) ? fields.driver_owner_id : null}
											loadOptions={this.loadDropDownOptions}
											onChange={this.handleChange("driver_owner_id")}
											placeholder={<label htmlFor="driver_owner">{translate("equipment.select_assignee")}</label>}
											additional={{ page: 1, type: "driver_owner" }}
											styles={customStyles}
											isClearable={true}
											components={customComponents}
										/>
									</div>
									<span className="error">{errors["driver_owner_id"]}</span>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-4">
									<div className="form-check-group">
										<label htmlFor="is_rental">{translate("employee.is_rental")}</label>
										<div className="form-group checkbox-row employee-checkbox-row">
											<div className="form-check form-check-inline custom-checkbox">
												<input className="form-check-input" type="radio" id="show_on_rental" name="is_rental" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_rental")} checked={fields.is_rental && fields.is_rental.value === String(RADIO_YES) ? true : false} />
												<label className="form-check-label" htmlFor="show_on_rental">{translate("employee.yes")}</label>
											</div>
											<div className="form-check form-check-inline custom-checkbox">
												<input className="form-check-input" type="radio" id="not_show_on_rental" name="is_rental" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_rental")} checked={fields.is_rental && fields.is_rental.value === String(RADIO_NO) ? true : false} />
												<label className="form-check-label" htmlFor="not_show_on_rental">{translate("employee.no")}</label>
											</div>
										</div>
										<span className="error text-danger">{errors["is_rental"]}</span>
									</div>
								</div>
							</div>
							<div className="employee-submit d-flex flex-wrap align-items-center">
								<button type={submitted ? "button" : "submit"} className="btn btn-fill">
									{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
								</button>
								<div>
									<Link to="/equipments" className="btn btn-outline">{translate("system.cancel")}</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</BlockUi>
			{
				lightBoxShow &&
				<Lightbox mainSrc={equipmentImgUrl+fields.equipment_img.value} onCloseRequest={() => this.setState({ lightBoxShow: false })} />
			}
		</>
	}
}
