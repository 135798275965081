import 'react-image-lightbox/style.css';

import React, { Component } from "react";
import { callBackendAPI,upperCaseOfName, handleFormInputs,customConfirmBox,reFormatSuccess} from '../../helpers/common';
import { STATUS_SUCCESS , PAGING_LENGTH_MENU, RADIO_YES, RADIO_NO,EXPORT_PDF,EXPORT_CSV,ACTIVE_DEACTIVE_DROPDOWN,ACTIVE,INACTIVE, PROJECT_MANAGER,AGREEMENT_TYPE_DROPDOWN}  from '../../config/global_constants';
import { translate } from './../../helpers/intl_helpers';
import { NavLink as Link} from "react-router-dom";
import EllipsisPagination from './../Pagination';
import { Table } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import Select from 'react-select';
import Auth from './../../helpers/Auth';

import AddForm from './AddSubContractorForm';
import closeIcon 	from '../../assets/images/close_icon.svg';
import dataTable 	from '../../assets/images/data-table.svg';
import dataAsc 		from '../../assets/images/data-asc.svg';
import dataDesc 	from '../../assets/images/data-desc.svg';

class SubcontractorList extends Component {

	constructor(props) {
        super(props)

        this.state = {
			isLoading:	true,
			fields   : {},
			searchStats: {status: { value: ACTIVE, label: "Active" }},
			showModel: false,
            userList : [],
            editId: null,
			isAddEmployeeFormVisible: false,
			fileUrl : "",
			noImgUrl: "",
			currentPage: 1,
			totalRecords: 0,
			totalPages	: 0,
			rowsPerPage	: PAGING_LENGTH_MENU[0],
			sortStats		:	{},
        };

		this.toggleModal 			  = this.toggleModal.bind(this);
		this.getTableList    		  =	this.getTableList.bind(this);
		this.handleChangePage    	  =	this.handleChangePage.bind(this);
		this.resetSearchStats    	  =	this.resetSearchStats.bind(this);
		this.handlePageLengthChange	  =	this.handlePageLengthChange.bind(this);
		this.handleDeleteEmployee 	  =	this.handleDeleteEmployee.bind(this);
		this.exportTableContent		  =	this.exportTableContent.bind(this);
    }

	toggleModal(close , editId) {
		this.setState({
			showModel: close,
			editId   : editId
		});
	}

	handleChange = (fieldName) => (fieldVal) => {
		this.setState({
			searchStats: {
				...this.state.searchStats,
				[fieldName]: fieldVal
			}
		});
	};

    componentDidMount() {
		this.getTableList();
    }

	/**
	* For manage page records limit stats
	*
	* @param lengthOpt as selected limit option params
	*
	* @return null
	*/
	handlePageLengthChange = (lengthOpt) => {
		if(lengthOpt && lengthOpt.value && !isNaN(lengthOpt.value)){
			this.setState({
				rowsPerPage : lengthOpt.value,
				currentPage	: 1,
			},()=>{
				this.getTableList();
			});
		}
	};

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();
		this.setState({currentPage: newPage},()=>{
			this.getTableList();
		});
	};

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({
			searchStats	: {},
			currentPage	: 1,
		},()=>{
			this.getTableList();
		});
	};

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()


	/**
	* For export PTO listing
	*/
	exportTableContent = (fileType) => {
		const {searchStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		/** Set api params */
		let apiReq = {model: 'employee', method: 'employeeExports', file_type: fileType,is_subcontractor : true,allRecords:true};
		if(searchStats && Object.keys(searchStats).length){
			apiReq.company_name		=	searchStats.company_name 	? searchStats.company_name.value 	:"";
			apiReq.email 			=	searchStats.email 			? searchStats.email.value 			:"";
			apiReq.mobile_number 	=	searchStats.mobile_number 	? searchStats.mobile_number.value 	:"";
			apiReq.is_flagger 		=	searchStats.is_flagger 		? searchStats.is_flagger.value 		:"";
			apiReq.status	 		=	searchStats.status 			? searchStats.status.value 			:INACTIVE;
			apiReq.state			=	searchStats.state 			? searchStats.state.value 			:"";
			apiReq.agreement_type	=	searchStats.agreement_type 	? searchStats.agreement_type.value 	:"";
		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					const newTab = window.open(response.data[0].file_url, '_blank');
					if (newTab) newTab.focus();
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	/**
	 * Manage sort event
	 */
	manageSortingEvent = (event, field, dir) => {
		event.preventDefault();

		this.setState({
			sortStats	: {key: field, dir: dir},
			currentPage	: 1,
		},()=>{
			this.getTableList();
		});
	};

	/**
	* For get employee listing
	*/
	getTableList = () => {
		const { rowsPerPage, currentPage, searchStats, sortStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{
			/** Set api params */
			let skip 	=	(currentPage-1)*rowsPerPage;
			let apiReq 	=	{ model: 'employee', method: 'list',is_subcontractor : true, length: rowsPerPage, start: skip};
			if(sortStats && sortStats.key) apiReq = {...apiReq, ...{sort_by: sortStats.key, sort_direction: sortStats.dir} };
			if(searchStats && Object.keys(searchStats).length){
				Object.keys(searchStats).forEach(key=>{
					if(searchStats[key]){
						apiReq = {...apiReq, ...{[key]: searchStats[key].value ? searchStats[key].value :""} }
					}
				});
			}

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						userList 	:	response.data[0].result,
						totalRecords: 	response.data[0].recordsTotal,
						totalPages	: 	Math.ceil(response.data[0].recordsTotal/rowsPerPage),
						isLoading	: 	false,
						fileUrl		:	response.data[0].file_url,
						noImgUrl	:	response.data[0].no_img_url
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getTableList()

    /** Function to handle image lightbox */
    handleImageClick = (imageUrl) => {
        this.setState({
            lightBoxShow: true,
            selectedImage: imageUrl
        });
    }

	/** Function to handle delete button click */
	handleDeleteEmployee = (employeeId) => {
		const confirmation = customConfirmBox(translate('subcontractor.you_want_to_delete_subcontractor'));
		confirmation.then((confirmed) => {
			if (confirmed) {
				callBackendAPI([{ model: 'employee', method: 'deleteOne', employee_id: employeeId ,is_subcontractor : true }]).then((response) => {
					if (response.success) {
						this.handleChangePage();
						reFormatSuccess(response.data[0].message);
					}
				}).catch(err => console.log(err));
			}
		});
	}


	render() {
        const {userList , fileUrl , noImgUrl ,showModel, searchStats, currentPage, lightBoxShow, selectedImage, rowsPerPage, totalPages, isLoading , editId, sortStats} = this.state;

		return <>
			<div className="dashboard-content">
				<div className="dashboard-heading d-flex justify-content-between align-items-center section-head">
				<div className="left-heading">
					<h1>{translate("subcontractor.subcontractor_list")}</h1>
				</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter " data-bs-toggle="collapse" data-bs-target="#subContractorcollapseOne" aria-expanded="true" aria-controls="subContractorcollapseOne">
							<img src="images/filter.svg" alt="Img" width={29} height={26} />
						</Link>
						<Link to="#" className="btn btn-fill" onClick={() => this.toggleModal(true)}>{translate("subcontractor.add_subcontractor")}</Link>
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="accordionExample">
						<div className="accordion-item">
							<div id="subContractorcollapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#subContractorcollapseOne" aria-expanded="true" aria-controls="subContractorcollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<div className="form-floating mb-3">
														<input type="text" className="form-control" id="company_name" placeholder={translate("subcontractor.company_name")} value={searchStats["company_name"] ? searchStats["company_name"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "company_name")}/>
														<label htmlFor="company_name">{translate("subcontractor.company_name")}</label>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<div className="form-floating mb-3">
														<input type="text" className="form-control" id="email" placeholder={translate("employee.email")} value={searchStats["email"] ? searchStats["email"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "email")}/>
														<label htmlFor="email">{translate("subcontractor.email")}</label>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<div className="form-floating mb-3">
														<input type="mobile_number" className="form-control" id="mobile_number" placeholder={translate("employee.mobile_number")} value={searchStats["mobile_number"] ? searchStats["mobile_number"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "mobile_number")}/>
														<label htmlFor="email">{translate("subcontractor.mobile_number")}</label>
													</div>
												</div>
											</div>
											
											<div className="col-lg-4">
												<div className=''>
													<div className="custom-form-group form-group">
														<Select
															className="custom-react-select"
															options={[
																{label: translate("system.yes"), value: String(RADIO_YES)},
																{label: translate("system.no"), value: String(RADIO_NO)}
															]}
															value={searchStats.is_flagger ? searchStats.is_flagger : null}
															closeMenuOnSelect={true}
															isClearable={true}
															onChange={this.handleChange("is_flagger")}
															placeholder={<label htmlFor="is_flagger">{translate("employee.is_flagger")}</label>}
														/>
													</div>
												</div>
											</div>

											<div className="col-lg-4">
												<div className=''>
													<div className="custom-form-group form-group">
													<Select
														className="custom-react-select mx-1"
														options={ACTIVE_DEACTIVE_DROPDOWN.map(key=>{return {value: key.value, label: key.label }})}
														// value={{ value: ACTIVE, label: "Active" }}
														value={searchStats.status ? searchStats.status : null}

														closeMenuOnSelect={true}
														onChange={this.handleChange("status")}
														placeholder={<label htmlFor="is_flagger">{translate("employee.status")}</label>}

													/>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<div className="form-floating mb-3">
														<input type="text" className="form-control" id="state" placeholder={translate("employee.state")} value={searchStats["state"] ? searchStats["state"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "state")}/>
														<label htmlFor="state">{translate("subcontractor.state")}</label>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className=''>
													<div className="custom-form-group form-group">
													<Select
														className="custom-react-select mx-1"
														options={AGREEMENT_TYPE_DROPDOWN.map(key=>{return {value: key.value, label: key.label }})}
														value={searchStats.agreement_type ? searchStats.agreement_type : null}

														closeMenuOnSelect={true}
														onChange={this.handleChange("agreement_type")}
														placeholder={<label htmlFor="agreement_type">{translate("subcontractor.agreement_type")}</label>}

													/>
													</div>
												</div>
											</div>
											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-lg-6">
						<div className="form-group d-flex align-items-center mb-3 table-count">
							{translate("system.show")}
							<Select
								className="custom-react-select mx-1"
								options={PAGING_LENGTH_MENU.map(key=>{return {value: key, label: key }})}
								value={{label:rowsPerPage, value:rowsPerPage}}
								closeMenuOnSelect={true}
								onChange={this.handlePageLengthChange}
							/>
							{translate("system.entries")}
						</div>
					</div>
					<div className="col-lg-6 text-end">
						<div className="form-group export-btns">
							<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportTableContent(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
							<Link to="#" className="btn btn-fill" onClick={()=>{this.exportTableContent(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
							<a href="/" ref={this.anchorRef} className='d-none' target='_new'>{translate("reports.export_as_csv")}</a>
						</div>
					</div>
				</div>

                <div className="users-list">
					<div className="table-responsive theme-table">
						<Table bordered responsive>
							<thead>
								<tr>
									<th width='10%'>
										{translate('subcontractor.image')}
									</th>
									<th width='15%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "first_name", (sortStats && sortStats.key === "first_name" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('subcontractor.name')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "first_name") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "first_name" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='15%'>
										{translate('subcontractor.contact_name')}
									</th>
									<th width='15%'>
										{translate('subcontractor.email')}
									</th>
									<th width='15%'>
										{translate('subcontractor.mobile')}
									</th>
									<th width='10%'>
										{translate('subcontractor.status')}
									</th>
									<th width='10%'>
										{translate('subcontractor.action')}
									</th>
								</tr>
							</thead>
							<tbody>
								{isLoading ?
									<tr>
										<td className='text-center'>
											<img src="/images/pagination_loader.gif" alt="loading-icon" />
										</td>
									</tr>
								: userList && userList.length ?
									userList.map((row, rowIndex) => (
										<tr key={"subcontractor-list" + rowIndex}>
											<td>
												<div className="user-info">
													<span className={`sort-name ${(!row.image) ? 'greenbg' : ''}`}>
														{row && row.image ? (
															<img src={`${fileUrl}${row.image}`} alt="employee" onError={(event) => event.target.src = noImgUrl} onClick={() => this.handleImageClick(`${fileUrl}${row.image}`)} />
														) : (
															upperCaseOfName(row.first_name ? row.first_name : "", row.last_name ? row.last_name : "")
														)}
													</span>
													{/* <div className="user-info-details">
														<strong>{row.company_name ? row.company_name : ""}</strong>
													</div> */}
												</div>
											</td>
											<td>
												<span className="user-type">
													<strong>{row.company_name ? row.company_name : ""}</strong>
												</span>
											</td>
											<td>
												<span className="user-type">
													{/* <img src="/images/industry-icon.svg" alt="img" width={19} height={21} /> */}
													{row.contact_name ? row.contact_name : ""}
												</span>
											</td>
											<td>
												<span className="user-type">
													<img src="/images/mail-icon.svg" alt="img" width={19} height={21} />
													{row.email ? (
														<a href={`mailto:${row.email}`} className="email-link">
															{row.email}
														</a>
													) : ""}
												</span>
											</td>
											<td>
												<span className="user-type">
													<img src="/images/call-icon.svg" alt="img" width={19} height={21} />
													{row.mobile ? row.mobile : ""}
												</span>
											</td>
											<td>
												<span className={row.active ? "text-success" : "text-danger"}>
													{/* <img src="/images/call-icon.svg" alt="img" width={19} height={21} /> */}
													{row.active ? "Active" : "Deactive"}
												</span>
											</td>
                                            <td>
                                                <div className="moreaction">
													{(Auth.getPermission() !== PROJECT_MANAGER) &&													
														<Link to="#" onClick={() => this.toggleModal(true, row._id)}>
															<img src="images/edit-icon.svg" alt="img" width={21} height={21} />
														</Link>
													}
                                                    <Link to="#">
                                                        <img src="images/delete-icon.svg" alt="img" width={19} height={21} onClick={() => this.handleDeleteEmployee(row._id)} />
                                                    </Link>
                                                </div>
                                            </td>
										</tr>
									))
								:
									<tr>
										<td>
											<div className="text-center">{translate("employee.no_data_available_in_table")}</div>
										</td>
									</tr>
								}
							</tbody>
						</Table>
					</div>

					<EllipsisPagination currentPage={currentPage} totalPages={totalPages} onChange={this.handleChangePage} />
				</div>

                {
					lightBoxShow &&
					<Lightbox
						mainSrc={selectedImage}
						onCloseRequest={() => this.setState({ lightBoxShow: false })}
					/>
				}
			</div>

		  	{showModel ?
				<AddForm editId={editId} fetchEmployeeList={this.getTableList} toggleModal={this.toggleModal}/>
			: null}
		</>
	}
}

export default SubcontractorList;
