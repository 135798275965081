import React, { Component } from "react";

import { translate  } from '../../../helpers/intl_helpers';


export default class AssetDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('assetDetailsModal');
	}// end closeModal()


	render() {
		const { assetDetails } = this.props;
		let detailType = assetDetails && assetDetails.state && assetDetails.state.type ? assetDetails.state.type : "";
		return (
			<div className="modal fade  crew-modal" id="assetDetailsModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>
						<div className="modal-body">
							<h3>{translate('calendar.details')}</h3>
							{(detailType === "asset") ? 
								(<div className="list-group list-group-flush">
									<div className="row">
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.asset_name')}</p>
												<div dangerouslySetInnerHTML={{__html:assetDetails.state ? assetDetails.state.groupName : ""}} />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.description')}</p>
												<div>{assetDetails.state ? assetDetails.state.description : ""}</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.make')}</p>
												<div>{assetDetails.state ? assetDetails.state.equipment_make : ""}</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.model')}</p>
												<div>{assetDetails.state ? assetDetails.state.equipment_model : ""}</div>
											</div>
										</div>
									</div>
								</div>) :
								(<div className="list-group list-group-flush">
									<div className="row">
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.asset_name')}</p>
												<div dangerouslySetInnerHTML={{__html:assetDetails.state ? assetDetails.state.groupName : ""}} />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.email')}</p>
												<div>{assetDetails.state ? assetDetails.state.email : ""}</div>
											</div>
										</div>
									</div>
								</div>)
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
