import React, {Component} from 'react'
import {PropTypes} from 'prop-types'

class MemberItem extends Component
{
    // constructor(props){
    //     super(props);
    // }

    static propTypes = {
        task: PropTypes.object.isRequired,
    }

    render(){
        const {task, isDragging, connectDragSource, connectDragPreview, setAssetItem} = this.props;

        let dragContent = <li className="cursor-pointer" style={{ backgroundColor: task.state.bgColor }} onClick={() => setAssetItem(task)}>
            <img src={task.state.image} alt=""  />
            <span>{task.name}</span>
            </li>;

        return (
            isDragging ? null : (

                        connectDragPreview(
                            connectDragSource(dragContent)
                        )

            )
        )
    }
}

export default MemberItem
