import React, { Component } from 'react';
import { NavLink as Link} from "react-router-dom";

import { callBackendAPI} from './../helpers/common';

export class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render shows the fallback UI
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		/** get data table data list */
		callBackendAPI([{ model: 'home', method: 'writeLogs', error : error.toString(), componentStack: errorInfo.componentStack}],{},false).then(response=>{
		}).catch(err => console.log(err));
	}

  render() {
	if(this.state.hasError){
      return (
		<div className="login-page 404-page">
			<div className="container">
				<div className="row text-center">
					<div className="logo">
						<Link to="/">
							<figure>
								<img src="/images/500-error.png" alt="logo"/>
							</figure>
						</Link>
					</div>
				</div>
			</div>
		</div>
      );
    }
    return this.props.children;
  }
};