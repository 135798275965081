
import React, {Component} from 'react'
import Select from 'react-select';
import { translate } from '../../../helpers/intl_helpers'

import { handleFormValidation} from '../../../helpers/common';


class AssignModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobId : "",
            type : this.props.type,
            foremanId : this.props.foremanId,
            memberAsseId : this.props.memberAsseId,
            jobOptions : this.props.jobOptions,
            assignMemberAssetForJob : this.props.assignMemberAssetForJob,
            fields  : {},
            errors  : {},
            
        };
    }


    handleOnClick = (e) => {
        const { foremanId, type, memberAsseId, assignMemberAssetForJob } = this.state;
        const { job_id } = this.state.fields;
        let params 			= [];
		let validations 	= {};
        let sendToAPI       = false;

        params = [{
            job_id	: 	(job_id && job_id.value)      ?	job_id.value		        :"",
        }]

        validations = {
            job_id	    : { "label": translate('calendar.job_id'), "rules" :["select"] },
        }

        handleFormValidation(validations,this.state.fields, params, sendToAPI,true,true).then(async(response)=>{
            if(!response.success) return this.setState({ errors : response.errors});

            assignMemberAssetForJob(job_id.value,foremanId,type,memberAsseId);
        }).catch(err => console.log(err));
    }


    handleChange = (fieldName) => (fieldVal) => {
        this.setState({
            foremanId: fieldVal.foreman_id,
            fields: {
                ...this.state.fields,
                [fieldName]: fieldVal
            }
        });
    };

    render() {
        const { fields, jobOptions } = this.state;
        return (
            <div className="modal fade  crew-modal" id="assignModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-s">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h3>{translate("calendar.assign_job")}</h3>
                                <div className='add-job-group'>

                                    <div className="form-group form-floating ">
                                        <Select
                                            className="custom-react-select"
                                            value={fields.job_id ? fields.job_id: null}
                                            options={jobOptions}
                                            onChange={this.handleChange("job_id")}
                                            closeMenuOnSelect={true}
                                            placeholder={<div><label htmlFor="job">{translate('calendar.select_job')}<span className="error text-danger">*</span></label></div>}
                                        />
                                        <span className="error text-danger">{this.state.errors["job_id"]}</span>
                                    </div>
                                    <button type={"button"} className="btn btn-fill width-initial mt-0" onClick={() => this.handleOnClick()}>{translate("system.submit")}</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AssignModal
