import '@availity/block-ui/src/BlockUi.css';

import React, { Component} from "react";
import { translate } from './../helpers/intl_helpers';
import { NavLink as Link } from "react-router-dom";
import { callBackendAPI, handleFormValidation,reFormatSuccess,handleFormInputs } from '../helpers/common';
import { STATUS_SUCCESS}  from './../config/global_constants';
import BlockUi from '@availity/block-ui';
import { UserDetails }  from '../UserDetails';




class EditProfile extends Component {
    static contextType = UserDetails;
    
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
			errors: {},
			fields: {},
			submitted: false,
            passRequired: false
        };

        this.handleFormInputs 			    =	this.handleFormInputs.bind(this);
		this.handleEditProfileFormSubmit 	=  	this.handleEditProfileFormSubmit.bind(this);

    }

    /**
	* Get initial details
	*/
	componentDidMount() {
		callBackendAPI([{model: 'my_account', method: 'getUserDetails'}]).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {                
				let userData = response.data[0].result || {};
				this.setState({
					fields: {
						first_name	: {value: userData.first_name 	|| ""},
						last_name	: {value: userData.last_name 	|| ""},
						email 		: {value: userData.email 		|| ""}
					},
					isLoading: false
				});
			}else {
				this.setState({ isLoading: true });
			}
		}).catch(err => console.log(err));
	
	}

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fields !== this.state.fields) {
            this.updatePasswordRequired();
        }
    }

    updatePasswordRequired() {
        const { old_password, password, confirm_password } = this.state.fields;

        let passRequired = false;
        if ((old_password && old_password.value) || (password && password.value) || (confirm_password && confirm_password.value)) {
            passRequired = true;
        }
        this.setState({ passRequired });
    }


    /**
	* For manage form submit event
	*
	* @param e 			as 	current form object
	* @param formType	as 	form type (login, signup)
	*
	* @return null
	*/
	handleEditProfileFormSubmit(e){
		e.preventDefault();

		const {first_name,last_name,email,old_password, password, confirm_password , passRequired} = this.state.fields;
        this.setState({ submitted: true },()=>{

            /** Api Requested Data **/
            let params = [{
                model				: 	'my_account',
                method				: 	'editProfile',
                first_name			: 	(first_name && first_name.value)                ? first_name.value       :"",
                last_name			: 	(last_name && last_name.value)	                ? last_name.value	     :"",
                old_password	    : 	(old_password && old_password.value)            ? old_password.value	 :"",
                password    	    : 	(password && password.value) 		            ? password.value		 :"",
                confirm_password    : 	(confirm_password && confirm_password.value) 	? confirm_password.value :"",
            }];


            /** Validation Rules **/
            let validations = {
                first_name	: {"label": translate('first_name','First Name'), "rules" :["required"]},
                last_name	: {"label": translate('last_name','Last Name'), "rules" :["required"]},
            }

            if(passRequired){
                validations.old_password        =	{"label": translate('user.current_password','Current Password'), "rules" :["required"]};
                validations.password            =	{"label": translate('user.new_password','New Password'), "rules" :["required","minLength"]};
                validations.confirm_password    =	{"label": translate('user.confirm_password','Confirm Password'), "rules" :["required","minLength","equal"]};
            }

            /** Handle validation and api response **/
            handleFormValidation(validations,this.state.fields, params).then(response =>{
                this.setState({submitted: false});

                if (!response.success) {
                    this.setState({ errors: response.errors });
                    return;
                }

                const { userDetails } = this.context;
                userDetails.full_name = first_name.value+" "+last_name.value;

                this.setState({
                    fields: {
                        first_name	: {value: first_name.value || ""},
                        last_name	: {value: last_name.value || ""},
                        email 		: {value: email.value || ""}
                    }
				});

                reFormatSuccess(response.data.message[0].msg);
                this.setState({ submitted: false, errors: {} });

            }).catch(err => console.log(err));
        });

	}

    /**
	* For update state fileds values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response = handleFormInputs(e, field, this.state.fields);
		this.setState(response, () => {
            this.updatePasswordRequired();
        });
	}// end handleFormInputs()

    render() {
		const { submitted, fields, errors, isLoading, passRequired} = this.state;

        return <>
			<BlockUi tag="div" blocking={isLoading}>
				<div className="dashboard-content">
					<div className="dashboard-heading d-flex justify-content-between align-items-center">
						<div className="left-heading">
							<h1>{translate("user.update_profile")}</h1>
						</div>
					</div>
					<div className="add-employee">
						<form className="adduser-form" onSubmit={(event) => this.handleEditProfileFormSubmit(event, 'addUser')} noValidate>
							<div className="row ">
								<div className="col-6">
									<div className="form-floating">
										<input
											id="first_name"
											type="text"
											name="first_name"
											className="form-control"
											placeholder={translate("user.first_name")}
											value={fields["first_name"] ? fields["first_name"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "first_name")}
										/>
										<span className="error text-danger">{errors["first_name"]}</span>
										<label htmlFor="first_name">{translate("user.first_name")}<span className="error text-danger">*</span></label>
									</div>
								</div>
                                <div className="col-6">
									<div className="form-floating">
										<input
											id="last_name"
											type="text"
											name="last_name"
											className="form-control"
											placeholder={translate("user.last_name")}
											value={fields["last_name"] ? fields["last_name"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "last_name")}
										/>
										<label htmlFor="last_name">{translate("user.last_name")}<span className="error text-danger">*</span></label>
										<span className="error text-danger">{errors["last_name"]}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="form-floating">
										<input
											id="email"
											type="text"
											name="email"
											className="form-control"
											placeholder={translate("user.email")}
											value={fields["email"] ? fields["email"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "email")}
                                            disabled={true}
										/>
										<label htmlFor="email">{translate("user.email")}</label>
									</div>
								</div>
							</div>
                            <div className="row">
								<div className="col-12">
									<div className="form-floating">
										<input
											id="current_password"
											type="password"
											name="current_password"
											className="form-control"
											placeholder={translate("user.current_password")}
											value={fields["old_password"] ? fields["old_password"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "old_password")}
										/>
                                        <span className="error text-danger">{errors["old_password"]}</span>
										<label htmlFor="current_password">{translate("user.current_password")}{passRequired && <span className="error text-danger">*</span>}</label>
									</div>
								</div>
                                <div className="col-12">
									<div className="form-floating">
										<input
											id="new_password"
											type="password"
											name="new_password"
											className="form-control"
											placeholder={translate("user.new_password")}
											value={fields["password"] ? fields["password"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "password")}
										/>
                                        <span className="error text-danger">{errors["password"]}</span>
										<label htmlFor="new_password">{translate("user.new_password")}{passRequired && <span className="error text-danger">*</span>}</label>
									</div>
								</div>
                                <div className="col-12">
									<div className="form-floating">
										<input
											id="confirm_password"
											type="password"
											name="confirm_password"
											className="form-control"
											placeholder={translate("user.confirm_password")}
											value={fields["confirm_password"] ? fields["confirm_password"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "confirm_password")}
										/>
                                        <span className="error text-danger">{errors["confirm_password"]}</span>
										<label htmlFor="confirm_password">{translate("user.confirm_password")}{passRequired && <span className="error text-danger">*</span>}</label>
									</div>
								</div>
							</div>
							<div className="employee-submit d-flex flex-wrap align-items-center">
								<button type={submitted ? "button" : "submit"} className="btn btn-fill">
									{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
								</button>
                                <div>
									<Link to="/" className="btn btn-outline">{translate("system.cancel")}</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</BlockUi>

        </>
    }

}
export default EditProfile